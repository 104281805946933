import React from 'react'

import PrivacyPolicyView from './PrivacyPolicyView'
import usePrivacyPolicy from './usePrivacyPolicy'

const PrivacyPolicy = () => {
  const state = usePrivacyPolicy()

  return (
    <PrivacyPolicyView {...state} />
  )
}

export default PrivacyPolicy
