import { List, Typography } from 'antd'
import React from 'react'
import MainImage from '../../components/MainImage/MainImage'
import VideoCard from '../../components/VideoCard/'
import { GetVideoInfosResponse } from '../../hooks/useGetVideoInfos/useGetVideoInfos'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import './myPlanned.less'

interface Props extends GetVideoInfosResponse {
  videosList?: Video[]
}

const { Title } = Typography

const MyPlannedView = (props: Props) => {
  return (
    <div className='my-planned'>
      <MainImage image='my_fav_o1qr3m' />
      <div className='my-planned__title'>
        <Title>Zaplanowane filmy</Title>
        <Title level={2}>
          Chcesz zaplanować nowy film na jutro? Zapisz go w tym miejscu aby go nie zgubić i nie marnować czasu na ponowne wyszukiwanie.
        </Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.videosList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              key={item._id}
              video={item}
              favorite={props.favoritesList?.find((favorite) => favorite.videoId === item?._id)}
              watched={props.watchedList?.find((watched) => watched.videoId === item?._id)}
              planned={props.plannedList?.find((planned) => planned.videoId === item?._id)}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyPlannedView
