import React from 'react'

import AboutView from './AboutView'
import useAbout from './useAbout'

const About = () => {
  const state = useAbout()

  return (
    <AboutView {...state} />
  )
}

export default About
