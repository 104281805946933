import { List, Typography } from 'antd'
import React from 'react'
import MainImage from '../../components/MainImage/MainImage'
import VideoCard from '../../components/VideoCard/'
import { GetVideoInfosResponse } from '../../hooks/useGetVideoInfos/useGetVideoInfos'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import './myFavorites.less'

interface Props extends GetVideoInfosResponse {
  videosList?: Video[]
}
const { Title } = Typography

const MyFavoritesView = (props: Props) => {
  return (
    <div className='my-favorites'>
      <MainImage image='my_fav_o1qr3m' style={{ objectPosition: '50% 100%' }} />
      <div className='my-favorites__title'>
        <Title>Moje ulubione filmy</Title>
        <Title level={2}>Zaznaczaj swoje ulubione filmy aby móc do nich zawsze wrócić bez konieczności ponownego wyszukiwania.</Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.videosList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              key={item._id}
              video={item}
              favorite={props.favoritesList?.find((favorite) => favorite.videoId === item?._id)}
              watched={props.watchedList?.find((watched) => watched.videoId === item?._id)}
              planned={props.plannedList?.find((planned) => planned.videoId === item?._id)}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyFavoritesView
