import React from 'react'
import MainImage from '../../components/MainImage/MainImage'

const LogOutView = () => {
  return (
    <div>
      <MainImage image='main_image_yvs4hz.jpg' small/>
      <div
        style={{
          width: '100%',
          height: 300,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        Trwa wylogowywanie...
      </div>
    </div>
  )
}

export default LogOutView
