import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { useCallback, useContext } from 'react'
import ClientContext from '../../providers/ClientProvider/client'
import { setLogin } from './redux/actions'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'

const useLogin = () => {
  const saving = useSelector((state: RootState) => state.loginState.saving)

  const history = useHistory()

  const client = useContext(ClientContext)

  const dispatch = useDispatch()

  const onFinish = useCallback(async (values) => {
    try {
      dispatch(
        setLogin({
          saving: true
        })
      )

      await client.authenticate({
        strategy: 'local',
        email: values.email,
        password: values.password
      })

      history.push(appRoutes.home.path)

    } catch (e) {
      // @ts-ignore
      message.error(e?.message)
    } finally {
      dispatch(
        setLogin({
          saving: false
        })
      )
    }
  },[dispatch, history])

  return {
    saving,
    onFinish
  }
}

export default useLogin
