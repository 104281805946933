import React from 'react'

import LogOutView from './LogOutView'
import useLogOut from './useLogOut'

const LogOut = () => {
  const state = useLogOut()

  return (
    <LogOutView
      {...state}
    />
  )
}

export default LogOut
