import React from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { Button, Descriptions, Form, Input, Modal, Radio, Space, Typography } from 'antd'
import { Client } from '../../../../providers/ClientProvider/client/services/clients/types'
import moment from 'moment'
import { appRoutes } from '../../../../containers/Router/routes'
import { Link } from 'react-router-dom'
import { Store } from 'rc-field-form/lib/interface'

interface Props {
  isActiveSubscription: boolean
  stripeSubscription?: any
  stripeSubscriptionStatus: 'loading' | 'error' | 'success'
  cancelSubscription: (values: Store) => void
  deletingSubscription: boolean
  renew: () => void
  renewing: boolean
  changePaymentMethod: () => void
  paymentMethod: boolean
  client?: Client
  cancelModalVisible: boolean
  setCancelModalVisible: (cancelModalVisible: boolean) => void
}

const CancelSubscriptionForm = (props: Props) => {
  const [reason, setReason] = React.useState('')
  return (
    <Form
      onFinish={props.cancelSubscription}
      size='large'
    >
      <Typography.Title level={2}>Cześć!</Typography.Title>
      <Typography.Paragraph>
        Przykro nam, że rezygnujesz<br />
        Poświęć nam 1 minutę i powiedz, co jest powodem Twojej rezygnacji?
      </Typography.Paragraph>
      <Form.Item
        name='reason'
        rules={[{ required: true, message: 'Wybierz jedną opocję.' }]}
      >
        <Radio.Group onChange={(e) => setReason(e.target.value)}>
          <Space direction='vertical'>
            <Radio value='nie mogę się zmobilizować do ćwiczeń w domu'>nie mogę się zmobilizować do ćwiczeń w domu</Radio>
            <Radio value='platforma znudziła mi się'>platforma znudziła mi się</Radio>
            <Radio value='rezygnuję tymczasowo, na pewno wrócę'>rezygnuję tymczasowo, na pewno wrócę</Radio>
            <Radio value='znalazłam coś innego'>znalazłam coś innego</Radio>
            <Radio value='platforma nie spełniła moich oczekiwań'>platforma nie spełniła moich oczekiwań</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      {
        reason === 'platforma nie spełniła moich oczekiwań' ?
          <Form.Item
            name='otherReason'
            rules={[{
              required: true,
              message: 'Pole wymagane.'
            }]}
          >
            <Input
              placeholder='Napisz krótko dlaczego?'
            />
          </Form.Item> : null
      }

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={props.deletingSubscription}
        >
          Potwierdzam rezygnację z abonamentu
        </Button>
      </Form.Item>
    </Form>
  )
}

const SubscriptionView = (props: Props) => {
  return (
    <ContentLoader loading={props.stripeSubscriptionStatus === 'loading'}>
      {
        props.isActiveSubscription
          ? <>
            <Descriptions title='Dane abonamentu:'>
              {props.stripeSubscription ?
                <>
                  <Descriptions.Item label='Cena'>{(props.stripeSubscription?.plan.amount / 100) + ',00 zł'}</Descriptions.Item>
                  <Descriptions.Item label='Abonament'>{props.stripeSubscription?.plan.interval === 'month' ? 'Miesięczny' : 'Roczny'}</Descriptions.Item>
                </> : null}
              <Descriptions.Item label='Ważny do'>{moment(props.client?.accessExpiryDate).format('DD/MM/yyyy')}</Descriptions.Item>
            </Descriptions>
            <Button
              onClick={props.changePaymentMethod}
              loading={props.paymentMethod}
              style={{ margin: '10px 10px 10px 0' }}
            >
              Dodaj metodę płatności
            </Button>
            {props.stripeSubscription?.cancel_at_period_end
              ? <>
                <Typography.Paragraph>Subskrypcja wygaśnie na koniec okresu rozliczeniowego</Typography.Paragraph>
                <Button
                  onClick={props.renew}
                  loading={props.renewing}
                  style={{ margin: '0 10px 10px 0' }}
                >
                  Wznów
                </Button>
              </>
              : <Button
                style={{ margin: '10px 10px 10px 0' }}
                onClick={() => props.setCancelModalVisible(true)}
              >
                Anuluj subskrypcję
              </Button>
            }
            <Typography.Paragraph>
              Jeśli chcesz zmienić subskrypcję skontaktuj się z nami za pomocą <Link to={appRoutes.help.path}>formularza kontaktowego</Link>
            </Typography.Paragraph>
            <Modal visible={props.cancelModalVisible} footer={null} onCancel={() => props.setCancelModalVisible(false)}>
              <CancelSubscriptionForm {...props}/>
            </Modal>
          </>
          : <>
            <Typography.Paragraph>Subskrypcja nieaktywna</Typography.Paragraph>
            <Button
              onClick={props.renew}
              loading={props.renewing}
              style={{ margin: '0 10px 10px 0' }}
            >
              Wznów/opłać
            </Button>
          </>
      }
    </ContentLoader>
  )
}

export default SubscriptionView
