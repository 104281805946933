import React from 'react'

import { FAQ } from '../../providers/ClientProvider/client/services/faq/types'
import { Collapse, Typography } from 'antd'
import ContentLoader from '../../components/ContentLoader'

import '../../layouts/AppLayout/appLayout.less'
import MainImage from '../../components/MainImage/MainImage'
import './faq.less'

const { Panel } = Collapse

interface IFAQsViewProps {
  faqList: FAQ[]
  status: 'loading' | 'error' | 'success'
}

const FAQsView = (props: IFAQsViewProps) => {
  return (
    <div className='faq'>
      <MainImage image='front_page_uaijnv' style={{ objectPosition: '50% 70%' }} />
      <div className='faq-title'>
        <Typography.Title level={1}>Najczęściej zadawane pytania</Typography.Title>
        <Typography.Title level={3}>
          Chcesz zacząć? Nie wiesz jak? Masz wątpliwości? <br />
          Sprawdź zanim rozpoczniesz ćwiczenia
        </Typography.Title>
      </div>
      <ContentLoader
        loading={props.status === 'loading'}
      >
        <Collapse
          style={{
            minHeight: 200
          }}
        >
          {props.faqList.map(faq => (
            <Panel
              header={faq.title}
              key={faq._id}
            >
              <p>{faq.content}</p>
            </Panel>
          ))}
        </Collapse>
      </ContentLoader>
    </div>
  )
}

export default FAQsView
