import { combineReducers } from 'redux'
import { appState } from '../containers/App/redux/reducer'
import { appLayoutState } from '../layouts/AppLayout/redux/reducer'
import { loginState } from '../pages/Login/redux/reducer'
import { videos } from '../pages/VideosList/redux/reducer'
import { privacyPolicy } from '../pages/PrivacyPolicy/redux/reducer'
import { video } from '../pages/VideoItem/redux/reducer'

const rootReducer = combineReducers({
  appState,
  appLayoutState,
  loginState,
  privacyPolicy,
  videos,
  video
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
