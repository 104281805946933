import React from 'react'
import SuccessView from './SuccessView'
import useSuccess from './useSuccess'

const Success = () => {
  useSuccess()

  return (
    <SuccessView />
  )
}

export default Success
