import { useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useCreateMutation, useGetQuery } from '../../query'
import { RootState } from '../../redux/reducers'
import { appRoutes } from '../../containers/Router/routes'

const useVerifyEmail = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const { data: client } = useGetQuery('clients', clientId)

  const [createAuthManagement, { status }] = useCreateMutation('clientAuthManagement')
  const history = useHistory()

  const onFinish = useCallback(async (values: any) => {
    if (!client) {
      return message.error('User not specified')
    }

    await createAuthManagement({
      action: 'verifySignupShort',
      value: {
        user: {
          email: client.email
        },
        token: values.code
      }
    })

    history.push(appRoutes.successVerifyEmail.path)
  }, [client])

  useEffect(() => {
    if (client?.isVerified) {
      history.push(appRoutes.home.path)
    }
  }, [client?.isVerified])

  return {
    onFinish,
    isSaving: status === 'loading',
    client
  }
}
export default useVerifyEmail
