import React from 'react'
import { Button, Result } from 'antd'
import MainImage from '../../components/MainImage/MainImage'
import './successVerifyEmail.less'

interface Props {
  onButtonPress: () => void
  saving: boolean
}

const SuccessVerifyEmailView = (props: Props) => {
  return (
    <div className='success-verify-email'>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Result
        status='success'
        title='Weryfikacja przebiegła poprawnie'
        subTitle='Przejdź do płatności'
        extra={
          <Button
            type='primary'
            size='large'
            onClick={props.onButtonPress}
            loading={props.saving}
          >
            Płacę
          </Button>
        }
      />
    </div>
  )
}

export default SuccessVerifyEmailView
