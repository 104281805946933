import React from 'react'

import MyWatchedView from './MyWatchedView'
import useMyWatched from './useMyWatched'

const MyWatched = () => {
  const state = useMyWatched()

  return (
    <MyWatchedView {...state} />
  )
}

export default MyWatched
