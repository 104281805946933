import Login from '../../pages/Login'
import Home from '../../pages/Home'
import NoMatch from '../../pages/NoMatch'
import LogOut from '../../pages/LogOut'
import VideosList from '../../pages/VideosList'
import FAQ from '../../pages/FAQ'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import ContactHelp from '../../pages/ContactHelp'
import VideoItem from '../../pages/VideoItem'
import About from '../../pages/About'
import MyFavorites from '../../pages/MyFavorites'
import MyPlanned from '../../pages/MyPlanned'
import MyWatched from '../../pages/MyWatched'
import MyAccount from '../../pages/MyAccount'
import SignUp from '../../pages/SignUp'
import VerifyEmail from '../../pages/VerifyEmail'
import SelectSubscription from '../../pages/SelectSubscription'
import Success from '../../pages/Success'
import SuccessVerifyEmail from '../../pages/SuccessVerifyEmail'
import React from 'react'
import AppLayout from '../../layouts/AppLayout'
import TermsAndConditions from '../../pages/TermsAndConditions'
import Studio from '../../pages/Studio'
import ResetPassword from '../../pages/ResetPassword'
import SetNewPassword from '../../pages/SetNewPassword'
import SuccessResetPassword from '../../pages/SuccessResetPassword'
import Programs from '../../pages/Programs'
import ProgramItem from '../../pages/ProgramItem'
import VideoPodcasts from '../../pages/VideoPodcasts'
import VideoPodcastItem from '../../pages/VideoPodcastItem'

export interface IRoute<T = React.FunctionComponent> {
  path: string
  order: number
  component: T // could not work out a good way of managing this
}

export interface INestedRoute<T = {}> {
  path: string
  order: number
  routes: T
  layout: any
}

interface IHomeRoutes extends Record<string, IRoute> {
  home: IRoute
  videosList: IRoute
  videoItem: IRoute
  favoriteVideos: IRoute
  plannedVideos: IRoute
  watchedVideos: IRoute
  account: IRoute
  login: IRoute
  logOut: IRoute
  privacy: IRoute
  rules: IRoute
  faq: IRoute
  help: IRoute
  // start: IRoute
  about: IRoute
  studio: IRoute
  signUp: IRoute
  verifyEmail: IRoute
  selectSubscription: IRoute
  success: IRoute
  successVerifyEmail: IRoute
  resetPassword: IRoute
  successResetPassword: IRoute
  setNewPassword: IRoute
  programs: IRoute
  programItem: IRoute
  noMatch: IRoute
}

const routes: INestedRoute<IHomeRoutes> = {
  path: '/',
  order: 1,
  layout: AppLayout,
  routes: {
    home: {
      path: '/',
      order: 1,
      component: Home
    },
    videosList: {
      path: '/videos',
      order: 1,
      component: VideosList
    },
    videoItem: {
      path: '/videos/:id',
      order: 1,
      component: VideoItem
    },
    favoriteVideos: {
      path: '/favorites',
      order: 1,
      component: MyFavorites
    },
    plannedVideos: {
      path: '/planned',
      order: 1,
      component: MyPlanned
    },
    watchedVideos: {
      path: '/watched',
      order: 1,
      component: MyWatched
    },
    account: {
      path: '/account',
      order: 1,
      component: MyAccount
    },
    login: {
      path: '/login',
      order: 1,
      component: Login
    },
    logOut: {
      path: '/logout',
      order: 1,
      component: LogOut
    },
    privacy: {
      path: '/privacy',
      order: 1,
      component: PrivacyPolicy
    },
    rules: {
      path: '/rules',
      order: 1,
      component: TermsAndConditions
    },
    faq: {
      path: '/faq',
      order: 1,
      component: FAQ
    },
    help: {
      path: '/help',
      order: 1,
      component: ContactHelp
    },
    // start: {
    //   path: '/start',
    //   order: 1,
    //   component: HowToStart
    // },
    about: {
      path: '/about',
      order: 1,
      component: About
    },
    studio: {
      path: '/studio',
      order: 1,
      component: Studio
    },
    signUp: {
      path: '/sign-up',
      order: 1,
      component: SignUp
    },
    verifyEmail: {
      path: '/verify-email',
      order: 1,
      component: VerifyEmail
    },
    selectSubscription: {
      path: '/select-subscription',
      order: 1,
      component: SelectSubscription
    },
    success: {
      path: '/success',
      order: 1,
      component: Success
    },
    successVerifyEmail: {
      path: '/success-verify-email',
      order: 1,
      component: SuccessVerifyEmail
    },
    resetPassword: {
      path: '/reset-password',
      order: 1,
      component: ResetPassword
    },
    successResetPassword: {
      path: '/success-reset-password',
      order: 1,
      component: SuccessResetPassword
    },
    setNewPassword: {
      path: '/set-password',
      order: 1,
      component: SetNewPassword
    },
    programs: {
      path: '/programs',
      order: 1,
      component: Programs
    },
    programItem: {
      path: '/programs/:id',
      order: 1,
      component: ProgramItem
    },
    videoPodcasts: {
      path: '/video-podcasts',
      order: 1,
      component: VideoPodcasts
    },
    videoPodcastItem: {
      path: '/video-podcasts/:id',
      order: 1,
      component: VideoPodcastItem
    },
    noMatch: {
      path: '*',
      order: 9999,
      component: NoMatch
    }
  }
}

// const routes: Record<Page, IRoute> = {
//   verifyBilling: {
//     path: '/verify-billing',
//     order: 4,
//     router: Route,
//     component: VerifyBilling,
//     exact: true
//   },
// }

// export interface IRoute {
//   path: string
//   order: number
//   router: any // could not  work out a good way of managing this
//   component: any // could not  work out a good way of managing this
//   menuKey?: string
//   exact: boolean
//   strict?: boolean
//   restrictAccess?: boolean
// }

// type Page =
//   'home' |
//   'videosList' |
//   'videoItem' |
//   'login' |
//   'logOut' |
//   'privacy' |
//   'faq' |
//   'help' |
//   'about' |
//   'start' |
//   'noMatch' |
//   'favoriteVideos' |
//   'plannedVideos' |
//   'watchedVideos' |
//   'account' |
//   'signUp' |
//   'verifyEmail' |
//   'verifyBilling' |
//   'selectSubscription' |
//   'success' |
//   'successVerifyEmail'

const appRoutes = routes.routes

export {
  appRoutes
}

export default routes
