export interface PrivacyPolicyState {
  loading?: boolean,
  policyId: string
}

export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY'

export interface SetPrivacyPolicyAction {
  type: typeof SET_PRIVACY_POLICY,
  payload: Partial<PrivacyPolicyState>
}

export type PrivacyPolicyActionTypes = SetPrivacyPolicyAction
