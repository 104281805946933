export interface VideoState {
  id: number,
}

export const SET_VIDEO = 'SET_VIDEO'

export interface SetVideosAction {
  type: typeof SET_VIDEO,
  payload: Partial<VideoState>
}

export type VideoActionTypes = SetVideosAction
