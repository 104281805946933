import { Typography } from 'antd'
import React from 'react'
import MainImage from '../../components/MainImage/MainImage'
import '../../layouts/AppLayout/appLayout.less'
import './about.less'

const { Title, Paragraph } = Typography

const AboutView = () => {
  return (
    <div className='about'>
      <MainImage image='about_dcllsy' style={{ objectPosition: '50% 75%' }} />
      <Title level={1} className='about__title'>
        Poznaj nas lepiej
      </Title>
      <div className='about__container'>
        <Title level={2}>
          O nas
        </Title>
        <Paragraph>
          Jesteśmy siostrami bliźniaczkami, od dzieciństwa żyjemy w ruchu a w PILATESie po prostu zakochałyśmy się!
        </Paragraph>
        <Paragraph>
          <b>Stworzyłyśmy platformę pilates-online.pl</b> oraz stacjonarne studio Pilatesu w Warszawie <b>ProSSto Pilates i Zdrowie.</b>
        </Paragraph>
        <Paragraph>
          Pilates zafascynował nas jako metoda usprawniania i dochodzenia do zdrowia. Metoda, która wspomaga rehabilitację w schorzeniach kręgosłupa i innych narządów ruchu jak barki, biodra. Pomaga w leczeniu dolegliwości bólowych pleców i kręgosłupa. Metoda, która przynosi niesamowite efekty już po kilku tygodniach ćwiczeń.
        </Paragraph>
        <Paragraph>
          Ukończyłyśmy liczne kursy i szkolenia Pilatesu w kraju i za granicą. Uczestniczyłyśmy w wielu warsztatach prowadzonych przez światowych nauczycieli Pilatesu tj. Miquel Jorge, Marian Talin, Brooke Siler, Gloria Gasperi i wielu innych. Kursy, które ukształtowały nas jako wyczulonych i uważnych na drugiego człowieka nauczycieli to Body Control Pilates® i Pilatistic Old School Pilates®.
        </Paragraph>
        <Paragraph>
          <b>Mamy doświadczenie w pracy z klientami dyskopatycznymi, po operacjach kręgosłupa, schorzeniach i urazach narządów ruchu tj barki, biodra. Na bazie pracy z problemami zdrowotnymi naszych klientów stworzyłyśmy specjalistyczne zdrowotne treningi nakierowane na konkretne dolegliwości narządów ruchu przede wszystkim kręgosłupa i pleców.</b>
        </Paragraph>
        <Paragraph>
          Jesteśmy zwolenniczkami zdrowego i mądrego podejścia do treningu, z poszanowaniem ograniczeń zdrowotnych danej osoby. Takiego, który buduje równowagę w ciele, zawiera kluczowe elementy: uelastycznianie całego ciała, mobilizację kręgosłupa i wszystkich stawów, wzmacnianie mięśni głębokich odpowiedzialnych za stabilizację kręgosłupa.
        </Paragraph>
        <Paragraph>
          Same doświadczyłyśmy różnych problemów zdrowotnych (zmiany dyskopatyczne kręgosłupa lędźwiowego, stany zapalne barku, operacje brzuszne). Dzięki Pilatesowi i mądremu ruchowi dochodziłyśmy do zdrowia i formy. Stąd rozumiemy potrzeby drugiego człowieka. Doświadczenie życiowe nauczyło nas wnikliwie patrzeć na ciało drugiego człowieka a przygotowanie nauczyciela ruchu sprawia że widzimy to nad czym należy pracować i wiemy jak pomagać innym w schorzeniach czy urazach.
        </Paragraph>
        <Paragraph>
          Współczesny człowiek mający dysfunkcje, ograniczenia ciała, napięcia i problemy bólowe, sam jest w stanie zbudować silne, wytrzymałe i elastyczne ciało. Może czuć się świetnie w swoim ciele i funkcjonować bez dolegliwości bólowych.
        </Paragraph>
        <Paragraph>
          Największą wartością dla nas jest praca z drugim człowiekiem, empatyczne spojrzenie na jego potrzeby i dolegliwości, pomoc w budowaniu sprawności i zdrowia oraz korzyści które jesteśmy w stanie mu przekazać. Największą radością za to są efekty naszych klientów, to jak się czują i jak funkcjonują dzięki naszej pracy. Czujemy jak praca ma sens i jak jesteśmy potrzebne.
        </Paragraph>
        <Paragraph>
          Naszą misją jest więc promowanie metody PILATES jako terapii ruchem bo tak ją stworzył sam Joseph; szerzenie i rozpowszechnianie wiedzy o tym prozdrowotnym nurcie, motywowanie i zachęcanie do ćwiczeń. Wiedząc jak zwiększa się ilość osób z problemami bólowymi pleców i kręgosłupa, chcemy pomagać innym czuć się lepiej i lepiej funkcjonować!
        </Paragraph>
        <Paragraph>
          Zapraszamy, Agata i Monika
        </Paragraph>
      </div>
    </div>
  )
}

export default AboutView
