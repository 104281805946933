import React from 'react'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import { Button, Col, Descriptions, Row, Space, Typography, Grid } from 'antd'
import { CloseOutlined, HeartFilled, HeartOutlined, PlusOutlined } from '@ant-design/icons'
import { Level } from '../../providers/ClientProvider/client/services/levels/types'
import { Equipment } from '../../providers/ClientProvider/client/services/equipments/types'
import ContentLoader from '../../components/ContentLoader'
import ReactPlayer from 'react-player'

import './videoItem.less'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'

interface Props {
  video?: Video,
  videos?: Video[],
  thumbnail?: string
  level?: Level,
  equipment?: Equipment,
  favorite: boolean,
  toggleFavorite: (e: React.MouseEvent<HTMLElement>) => void,
  planned: boolean,
  togglePlanned: (e: React.MouseEvent<HTMLElement>) => void,
  watched: boolean
  handleCardClick: () => void
  clientId: string | null
  activeAccount: boolean
  videoStatus: 'loading' | 'error' | 'success'
  levelStatus: 'loading' | 'error' | 'success'
  equipmentStatus: 'loading' | 'error' | 'success'
  favoriteStatus: 'loading' | 'error' | 'success'
  watchedStatus: 'loading' | 'error' | 'success'
  plannedStatus: 'loading' | 'error' | 'success'
  setAsWatched: () => void
  handleSubscribe: () => void
}
const { useBreakpoint } = Grid

const VideoItemView = (props: Props) => {
  const { xs } = useBreakpoint()
  return (
    <div>
      <div className='videos-item__gradient'/>
      <div style={{ height: '200px', width: '100%' }}/>
    <ContentLoader loading={props.videoStatus === 'loading'}>
      <div className='video-item'>
        <Typography.Title level={2}>
          {props.video ? props.video.name : ''}
        </Typography.Title>
        <Row gutter={16}>
          <Col xs={24} lg={18} xl={18}>
            {
              props.clientId && props.activeAccount &&
                <div className='wrapper'>
                  <ReactPlayer
                    className='player'
                    url={`https://info-prossto.wistia.com/medias/${props.video?.wisitaHashId}`}
                    onStart={() => props.setAsWatched()}
                    width='100%'
                    height='100%'
                  />
                </div>
            }
            {
              (!props.clientId || !props.activeAccount) &&
              <div className='wrapper'>
                <img
                  className='player'
                  src={props.thumbnail}
                  alt={props.video?.name}
                />
                <div
                  className='overlay'
                >
                  <div
                    className='overlay-content'
                  >
                    <Row gutter={[16, 16]}>
                      {
                        !xs && <Col xs={0} sm={18}>
                          <div className='wrapper'>
                            <ReactPlayer
                              className='player'
                              url={props.video?.sampleVideo}
                              width='100%'
                              height='100%'
                            />
                          </div>
                        </Col>
                      }
                      <Col xs={24} sm={6}>
                        <Typography.Paragraph>
                          Aby obejrzeć cały film
                        </Typography.Paragraph>
                        <Button size='small' type='primary' style={{ marginBottom: 10 }}>
                          <Link to={appRoutes.login.path}>zaloguj się</Link>
                        </Button>
                        <Typography.Paragraph>
                          lub
                        </Typography.Paragraph>
                        <Button size='small' type='primary'>
                          <Link to={appRoutes.selectSubscription.path}>dołącz teraz</Link>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            }
          </Col>
          <Col
            xs={24} lg={6} xl={6}
          >
            <Descriptions
              column={1}
            >
              <Descriptions.Item label='Poziom'>
                {props.level?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Sprzęt'>
                {props.equipment?.name}
              </Descriptions.Item>
              <Descriptions.Item label='Czas trwania'>
                {props.video?.duration ? (props.video?.duration / 60).toFixed(0) : 0} min
              </Descriptions.Item>
            </Descriptions>
            {
              props.clientId ?
                <Space
                  direction='vertical'
                >
                  <Button
                    icon={props.favorite ? <HeartFilled /> : <HeartOutlined />}
                    onClick={props.toggleFavorite}
                  >
                    {props.favorite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
                  </Button>
                  <Button
                    icon={props.planned ? <CloseOutlined /> : <PlusOutlined />}
                    onClick={props.togglePlanned}
                  >
                    {props.planned ? 'Usuń z zaplanowanych' : 'Dodaj do zaplanowanych'}
                  </Button>
                </Space>
                : null
            }
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xs={24}>
            <div dangerouslySetInnerHTML={{ __html: props.video?.description ? props.video.description : '' }}/>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Typography.Title level={4}>
              Zobacz inne lekcje i dodaj do zaplanowanych:
            </Typography.Title>
          </Col>
          {props.videos?.map(video =>
            <Col key={video._id} xs={24} sm={12} lg={6}>
              <Link to={`/videos/${video._id}`} className='videos-item__proposed'>
                <img
                  className='video-item__proposed-image'
                  src={video?.thumbnail?.url.replace('?image_crop_resized=200x120', '')}
                  alt='video proposition'
                />
                <Typography.Text>
                  <b>{video.name}</b>
                </Typography.Text>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    </ContentLoader>
    </div>
  )
}

export default VideoItemView
