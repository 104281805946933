import { List, Typography } from 'antd'
import React from 'react'
import MainImage from '../../components/MainImage/MainImage'
import VideoCard from '../../components/VideoCard/'
import { GetVideoInfosResponse } from '../../hooks/useGetVideoInfos/useGetVideoInfos'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import './myWatched.less'

interface Props extends GetVideoInfosResponse {
  videosList?: Video[]
}

const { Title } = Typography

const MyWatchedView = (props: Props) => {
  return (
    <div className='my-watched'>
      <MainImage image='my_fav_o1qr3m' />
      <div className='my-watched__title'>
        <Title>Obejrzane filmy</Title>
        <Title level={2}>
          Nie jesteś pewna czy dany film już obejrzałaś? A może chcesz powtórzyć serię ćwiczeń, którą zrobiłaś? Nie musisz szukać filmu. W tym miejscu znajdziesz wszystkie lekcje i programy, które odtworzyłaś i obejrzałaś.
        </Title>
      </div>
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        dataSource={props.videosList}
        renderItem={item => (
          <List.Item>
            <VideoCard
              key={item._id}
              video={item}
              favorite={props.favoritesList?.find((favorite) => favorite.videoId === item?._id)}
              watched={props.watchedList?.find((watched) => watched.videoId === item?._id)}
              planned={props.plannedList?.find((planned) => planned.videoId === item?._id)}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default MyWatchedView
