import React from 'react'
import { Button, Col, Row, Form, Typography, Input, Card, Checkbox } from 'antd'
import './contactHelp.less'
import { Link } from 'react-router-dom'
import MainImage from '../../components/MainImage/MainImage'

const { Title, Paragraph } = Typography

export interface MyContactHelpProps {
  onFinish: (values: { [key: string]: any }) => void
}

const ContactHelpView = (props: MyContactHelpProps) => {
  return (
    <div className='contact-help'>
      <MainImage image='front_page_uaijnv'/>
      <Card className='contact-help__container'>
        <Title level={1}>
          Masz pytanie?
        </Title>
        <Title level={2}>
          Sprawdź odpowiedź w <Link to='/faq'>często zadawane pytania</Link>
          <br />
          Jeżeli nie znalazłaś/eś odpowiedzi, napisz do nas!
        </Title>
        <Paragraph>
          Regularnie sprawdzamy maile i odpowiadamy na nie tak szybko jak jest to możliwe <br />
          Nawet kiedy nagrywamy  filmy i prowadzimy zajęcia ;) <br />
          Prosimy o cierpliwość, odpowiemy w ciągu 72h
        </Paragraph>
        <Form
          layout='horizontal'
          className='contact-help__form'
          onFinish={props.onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} md={8}>
              <Form.Item
                name='author'
                rules={[{ required: true, message: 'To pole jest obowiązkowe' }]}
              >
                <Input placeholder='Twoje imię i nazwisko' />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[{ required: true, type: 'email', message: 'To pole jest obowiązkowe' }]}
              >
                <Input placeholder='Twój adres e-mail' />
              </Form.Item>
              <Form.Item
                name='topic'
                rules={[{ required: true, message: 'To pole jest obowiązkowe' }]}
              >
                <Input placeholder='Temat' />
              </Form.Item>
            </Col>
            <Col xs={24} md={16}>
              <Form.Item
                name='content'
                rules={[{ required: true, message: 'To pole jest obowiązkowe' }]}
              >
                <Input.TextArea
                  placeholder='Wiadomość'
                  className='contact-help__long-text-input'
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='Zgoda'
            rules={[{ required: true, message: 'To pole jest obowiązkowe' }]}
            valuePropName='checked'
          >
            <Checkbox>
              Twoje dane będą przetwarzane m.in. w celu skontaktowania się z Tobą. Więcej o tym przeczytasz w <Link to='/privacy'>Polityce prywatności</Link>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>wyślij</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default ContactHelpView
