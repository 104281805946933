import React from 'react'

import MyFavoritesView from './MyFavoritesView'
import useMyFavorites from './useMyFavorites'

const MyFavorites = () => {
  const state = useMyFavorites()

  return (
    <MyFavoritesView {...state} />
  )
}

export default MyFavorites
