import React from 'react'

import SignUpView from './SignUpView'
import useSignUp from './useSignUp'

const SignUp = () => {
  const state = useSignUp()

  return (
    <SignUpView
      {...state}
    />
  )
}

export default SignUp
