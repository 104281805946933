import { AppLayoutActionTypes, AppLayoutState, SET_APP_LAYOUT } from './types'

const INITIAL_STATE: AppLayoutState = {
  breadcrumb: [],
  leftMenuCollapsed: false,
  leftMenuDrawerOpen: false,
  authenticationInProgress: true
}

export const appLayoutState = (
  state = INITIAL_STATE,
  action: AppLayoutActionTypes
): AppLayoutState => {
  switch (action.type) {
    case SET_APP_LAYOUT:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
