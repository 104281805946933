import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { useFindQuery } from '../../query'

const usePrivacyPolicy = () => {
  const {
    data: privacyPoliciesRes,
    status
  } = useFindQuery('privacyPolicies', {
    active: true
  })

  const html = useMemo<string>(() => {
    if (!privacyPoliciesRes?.data[0]) {
      return ''
    }

    return sanitizeHtml(privacyPoliciesRes?.data[0]?.content, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'a', 'h3', 'h2', 'h1', 'p'
      ])
    })
  }, [privacyPoliciesRes?.data[0]?.content])

  return {
    status,
    html
  }
}

export default usePrivacyPolicy
