import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { RootState } from '../../redux/reducers'
import { message } from 'antd'
import { useCreateMutation, useGetQuery } from '../../query'

const useResendVerificationEmail = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const { data: client, status: userStatus } = useGetQuery('clients', clientId)

  const [createAuthManagement, { status }] = useCreateMutation('clientAuthManagement')

  const resendVerificationEmail = useCallback(async () => {
    if (userStatus === 'success' && client) {
      await createAuthManagement({
        action: 'resendVerifySignup',
        value: {
          email: client.email
        }
      })
      message.info(`Kod weryfikacyjny wysłany na: ${client.email}`)
    }
  }, [userStatus])

  return {
    resendVerificationEmailLoading: status === 'loading',
    resendVerificationEmail
  }
}
export default useResendVerificationEmail
