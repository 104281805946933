import { useGetQuery } from '../../query'

const useSelectSubscription = () => {

  const {
    data: subscriptionPlan1,
    status: subscriptionPlan1Status
  } = useGetQuery('stripePlans', 'price_1QHkx8A9eQ3FzEF5SzHx8z4z')

  const {
    data: subscriptionPlan2,
    status: subscriptionPlan2Status
  } = useGetQuery('stripePlans', 'price_1QHkxKA9eQ3FzEF5hnRlx431')

  return {
    subscriptionPlan1,
    subscriptionPlan2
  }
}

export default useSelectSubscription
