import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { useGetQuery, useFindQuery, useCreateMutation, useDeleteMutation } from '../../query'
import { RootState } from '../../redux/reducers'

const useVideoCard = (videoId?: string) => {
  const history = useHistory()

  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const activeAccount = useSelector((state: RootState) => state.appState.activeAccount)
  const {
    data: video,
    status: videoStatus
  } = useGetQuery('videos', videoId)
  const {
    data: level,
    status: levelStatus
  } = useGetQuery('levels', video?.levelId)
  const {
    data: equipment,
    status: equipmentStatus
  } = useGetQuery('equipments', video?.equipmentId)
  const {
    data: favorites,
    status: favoriteStatus
  } = useFindQuery('favoriteVideos',
    clientId
      ? {
        clientId: clientId,
        videoId
      }
      : false
  )

  const {
    data: watchedData,
    status: watchedStatus
  } = useFindQuery('watchedVideos',
    clientId
      ? {
        clientId: clientId,
        videoId
      }
      : false
  )
  const {
    data: plannedData,
    status: plannedStatus
  } = useFindQuery('plannedVideos',
    clientId
      ? {
        clientId: clientId,
        videoId
      }
      : false
  )

  const [createFavorite] = useCreateMutation('favoriteVideos')
  const [deleteFavorite] = useDeleteMutation('favoriteVideos')
  const [createPlanned] = useCreateMutation('plannedVideos')
  const [deletePlanned] = useDeleteMutation('plannedVideos')
  const [createWatched] = useCreateMutation('watchedVideos')

  const favorite = useMemo(() => {
    return !!(favorites?.data.length)
  }, [favorites])

  const watched = useMemo(() => {
    return !!(watchedData?.data.length)
  }, [watchedData])

  const planned = useMemo(() => {
    return !!(plannedData?.data.length)
  }, [plannedData])

  const toggleFavorite = useCallback(async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!clientId) throw new Error('clientId not specified')
    if (favorite) {
      if (!favorites) throw new Error('Favorite not specified')
      await deleteFavorite(favorites.data[0]._id)
    } else {
      await createFavorite({
        clientId: clientId,
        videoId: videoId
      })
    }
  }, [clientId, favorite, favorites, deleteFavorite, createFavorite, videoId])

  const togglePlanned = useCallback(async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!clientId) throw new Error('clientId not specified')
    if (planned) {
      if (!plannedData) throw new Error('PlannedData not specified')
      await deletePlanned(plannedData.data[0]._id)
    } else {
      await createPlanned({
        clientId: clientId,
        videoId: videoId
      })
    }
  }, [clientId, planned, plannedData, deletePlanned, createPlanned, videoId])

  const handleCardClick = useCallback(() => {
    history.push(generatePath(appRoutes.videoItem.path, { id: videoId }))
  }, [history, videoId])

  const thumbnail = useMemo(() => {
    return video?.thumbnail?.url.replace('?image_crop_resized=200x120', '')
  }, [video])

  const setAsWatched = useCallback(async () => {
    if (!watched && clientId) {
      const res = await createWatched({
        clientId: clientId,
        videoId: videoId
      })
    }
  }, [watched, clientId, createWatched, videoId])

  return {
    video,
    thumbnail,
    level,
    equipment,
    favorite,
    toggleFavorite,
    planned,
    togglePlanned,
    watched,
    handleCardClick,
    clientId,
    videoStatus,
    levelStatus,
    equipmentStatus,
    favoriteStatus,
    watchedStatus,
    plannedStatus,
    activeAccount,
    setAsWatched
  }
}

export default useVideoCard
