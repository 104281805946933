import React, { useCallback } from 'react'
import { useFindQuery, useGetQuery, usePatchMutation, useCreateMutation } from '../../query'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { message } from 'antd'
import useDeleteAccount from '../VerifyEmail/useDeleteAccount'

const useMyAccount = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const activeAccount = useSelector((state: RootState) => state.appState.activeAccount)

  const {
    data: client
  } = useGetQuery('clients', clientId)

  const {
    data: stripeCustomersRes
  } = useFindQuery('stripeCustomers', {
    id: client?.customerId
  })

  const [
    patchStripeCustomer,
    {
      status: patchStatus
    }
  ] = usePatchMutation('stripeCustomers', {
    throwOnError: true
  })

  const {
    data: invoices
  } = useFindQuery('stripeInvoices', {
    customer: client?.customerId
  })

  const columns = [
    {
      title: 'Nr',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: 'Kwota',
      dataIndex: 'amount_due',
      key: 'amount_due',
      render: (amount: number) => (
        <span>{(amount / 100).toFixed(2)} zł</span>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <span>{status === 'paid' ? 'zapłacono' : (status === 'open' ? 'oczekujące' : status)}</span>
      )
    },
    {
      title: '',
      dataIndex: 'hosted_invoice_url',
      key: 'hosted_invoice_url',
      render: (url: string) => (
        <a href={url}> Więcej informacji </a>
      )
    },
    {
      title: '',
      dataIndex: 'invoice_pdf',
      key: 'invoice_pdf',
      render: (url: string) => (
        <a href={url}> Pobierz PDF </a>
      )
    }
  ]

  const onFinishBilling = useCallback(async (values: any) => {
    if (!client) return

    if (!stripeCustomersRes) {
      throw new Error('Stripe customer not created')
    }

    try {
      await patchStripeCustomer({
        id: stripeCustomersRes?.data[0]._id,
        data: {
          taxId: values.taxId,
          name: values.name,
          phone: values.phone,
          address: {
            city: values.city,
            country: values.country,
            line1: values.line1,
            line2: values.line2,
            postal_code: values.postal_code,
            state: values.state
          }
        }
      })

      message.success('Dane zaktualizowane pomyślnie.')
    } catch (e) {
      console.log(e)
      // ignore error
    }
  }, [client, stripeCustomersRes])

  const [updateClient] = usePatchMutation('clients')

  const onFinishProfile = useCallback(async (values: any) => {
    if (!client && !clientId) return

    let data = {
      ...values
    }

    delete data.email

    try {
      await updateClient({
        id: (clientId as string),
        data
      })

      message.success('Dane zaktualizowane pomyślnie.')
    } catch (e) {
      console.log(e)
      // ignore error
    }
  }, [client, clientId])

  const [createAuthManagement] = useCreateMutation('clientAuthManagement')

  const onFinishPassword = useCallback(async (values) => {
    if (!client && !clientId) return

    await createAuthManagement({
      action: 'passwordChange',
      value: {
        user: {
          email: client?.email
        },
        oldPassword: values.oldPassword,
        password: values.newPassword
      }
    })
    message.success('Dane zaktualizowane pomyślnie.')
  },[createAuthManagement, client])

  const { deleteAccount: handleDeleteAccount } = useDeleteAccount()

  return {
    stripeCustomers: stripeCustomersRes?.data[0],
    patchStatus,
    onFinishBilling,
    onFinishProfile,
    onFinishPassword,
    activeAccount,
    handleDeleteAccount,
    columns,
    invoices: invoices?.data,
    client
  }
}

export default useMyAccount
