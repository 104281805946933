import { useContext, useEffect } from 'react'
import ClientContext from '../../providers/ClientProvider/client'
import { message } from 'antd'
import config from '../../config'
import { appRoutes } from '../../containers/Router/routes'

const useLogOut = () => {
  const client = useContext(ClientContext)

  useEffect(() => {
    async function logOut () {
      try {
        await client.logout()

        window.location.href = appRoutes.home.path

        // history.push(appRoutes.home.path)
      } catch (error) {
        // the 404 error may happen if the user was removed and so the current client can not log in
        // @ts-ignore
        if (error && error.code === 404) {

          window.localStorage.removeItem(config.jwtStorageKey)
          window.location.reload()
        } else {
          // @ts-ignore
          message.error(error.message)
        }
      }
    }

    logOut()
  }, [])

  return {}
}

export default useLogOut
