import React from 'react'

import VerifyEmailView from './VerifyEmailView'
import useVerifyEmail from './useVerifyEmail'
import useResendVerificationEmail from './useResendVerificationEmail'
import useDeleteAccount from './useDeleteAccount'

const VerifyEmail = () => {
  const state = useVerifyEmail()
  const resendVerificationEmailState = useResendVerificationEmail()
  const identityChangeState = useDeleteAccount()

  return (
    <VerifyEmailView
      {...state}
      {...resendVerificationEmailState}
      {...identityChangeState}
    />
  )
}

export default VerifyEmail
