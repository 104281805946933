import { Col, Divider, Form, List, Row, Select, Typography } from 'antd'
import React from 'react'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import './videosList.less'

import VideoCard from '../../components/VideoCard'
import { GetVideoInfosResponse } from '../../hooks/useGetVideoInfos/useGetVideoInfos'
import { Equipment } from '../../providers/ClientProvider/client/services/equipments/types'
import { Level } from '../../providers/ClientProvider/client/services/levels/types'
import { Target } from '../../providers/ClientProvider/client/services/targets/types'

const { Option } = Select
const { Title } = Typography

export interface MyVideosViewProps extends GetVideoInfosResponse {
  videoStatus: 'loading' | 'error' | 'success'
  videosList?: Video[]
  targetsList?: Target[]
  targetStatus: 'loading' | 'error' | 'success'
  levelsList?: Level[]
  levelStatus: 'loading' | 'error' | 'success'
  equipmentsList?: Equipment[]
  equipmentStatus: 'loading' | 'error' | 'success'

  handlePaginateChange: any
  handleTargetFilterChange: any
  handleLevelFilterChange: any
  handleDurationFilterChange: any
  handleEquipmentFilterChange: any
  total: number
  currentPage: number
  initialValues: any // TODO fix types
  pageSize: number
}

const VideosListView = (props: MyVideosViewProps) => {
  return (
    <div className='my-videos'>
      <div className='my-videos__gradient' />
      <div style={{ height: '180px', width: '100%' }} />
      <Title>Filtruj i znajduj odpowiednie lekcje dla siebie</Title>
      <Form
        initialValues={props.initialValues}
      >
        <Row gutter={16}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name='target'
            >
              <Select
                onChange={props.handleTargetFilterChange}
                className='my-videos__filter-input'
                loading={props.targetStatus === 'loading'}
              >
                <Option value='all'>Wszystkie cele</Option>
                {props.targetsList?.map(target => (
                  <Option key={target._id} value={target._id}>{target.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name='level'
            >
              <Select
                onChange={props.handleLevelFilterChange}
                className='my-videos__filter-input'
                loading={props.levelStatus === 'loading'}
              >
                <Option value='all'>Wszystkie poziomy</Option>
                {props.levelsList?.map(level => (
                  <Option key={level._id} value={level._id}>{level.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name='duration'
            >
              <Select
                onChange={props.handleDurationFilterChange}
                className='my-videos__filter-input'
              >
                <Option key={1} value='all'>0-60 min</Option>
                <Option key={2} value='{"$lt":"900"}'>0-15 min</Option>
                <Option key={3} value='{"$lt":"1800","$gt":"900"}'>15-30 min</Option>
                <Option key={4} value='{"$lt":"2700","$gt":"1800"}'>30-45 min</Option>
                <Option key={5} value='{"$gt":"2700"}'>45-60 min</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              name='equipment'
            >
              <Select
                onChange={props.handleEquipmentFilterChange}
                className='my-videos__filter-input'
              >
                <Option value='all'>Każdy sprzęt</Option>
                loading={props.equipmentStatus === 'loading'}
                {props.equipmentsList?.map(equipment => (
                  <Option key={equipment._id} value={equipment._id}>{equipment.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 6
        }}
        pagination={{
          onChange: props.handlePaginateChange,
          pageSize: props.pageSize,
          total: props.total,
          current: props.currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        }}
        dataSource={props.videosList}
        loading={props.videoStatus === 'loading'}
        renderItem={item => (
          <List.Item>
            <VideoCard
              key={item._id}
              video={item}
              favorite={props.favoritesList?.find((favorite) => favorite.videoId === item?._id)}
              watched={props.watchedList?.find((watched) => watched.videoId === item?._id)}
              planned={props.plannedList?.find((planned) => planned.videoId === item?._id)}
            />
          </List.Item>
        )}
      />
    </div>
  )
}

export default VideosListView
