import React from 'react'

import '../../layouts/AppLayout/appLayout.less'
import ContentLoader from '../../components/ContentLoader'
import MainImage from '../../components/MainImage/MainImage'
import './termsAndConditions.less'
import { Card, Typography } from 'antd'

interface IPrivacyPolicyProps {
  status: 'loading' | 'error' | 'success'
  html: string
}
const { Title } = Typography

const TermsAndConditionsView = (props: IPrivacyPolicyProps) => {
  return (
    <div className='rules'>
      <MainImage image='front_page_uaijnv'/>
      <ContentLoader loading={props.status === 'loading'}>
        <Card
          className='rules__container'
        >
          <Title level={1}>
            Regulamin
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: props.html
            }}
          />
        </Card>
      </ContentLoader>
    </div>
  )
}

export default TermsAndConditionsView
