import { PrivacyPolicyActionTypes, PrivacyPolicyState, SET_PRIVACY_POLICY } from './types'

const INITIAL_STATE: PrivacyPolicyState = {
  policyId: ''
}

export const privacyPolicy = (
  state = INITIAL_STATE,
  action: PrivacyPolicyActionTypes
): PrivacyPolicyState => {
  switch (action.type) {
    case SET_PRIVACY_POLICY:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
