import useSubscription from './useSubscription'
import SubscriptionView from './SubscriptionView'
import React from 'react'

const Subscription = () => {
  const state = useSubscription()

  return (
    <SubscriptionView
      {...state}
    />
  )
}

export default Subscription
