import React from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import './setNewPassword.less'
import MainImage from '../../components/MainImage/MainImage'

interface ResetPasswordViewProps {
  onFinish: (values: any) => void
  loading: boolean
}

const SetNewPasswordView = (props: ResetPasswordViewProps) => {
  return (
    <div className='reset-password'>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Form
        name='reset-password'
        className='reset-password-form'
        onFinish={props.onFinish}
        size='large'
      >
        <Typography.Title level={2} style={{ textAlign: 'center' }}>Ustaw nowe hasło</Typography.Title>
        <Form.Item
          name='password'
          hasFeedback
          rules={[{
            required: true,
            message: 'Hasło musi zawierać minimum 8 znaków, literę, cyfrę oraz wielką literę.',
            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
          }]}
        >
          <Input
            placeholder='Nowe Hasło'
            type='password'
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Potwierdź hasło!'
            },
            ({ getFieldValue }) => ({
              validator (rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('Wprowadzone hasła nie są takie same.')
              }
            })
          ]}
        >
          <Input
            placeholder='Potwierdź hasło'
            type='password'
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='reset-password-form-button'
            disabled={props.loading}
          >
            {props.loading ? 'Trwa zmienianie hasła' : 'Ustaw hasło'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SetNewPasswordView
