import React from 'react'
import NoMatchView from './NoMatchView'

const NoMatch = () => {
  return (
    <NoMatchView />
  )
}

export default NoMatch
