import React from 'react'
import { Button, Collapse, Form, Input, Popconfirm, Table, Typography } from 'antd'
import './myAccount.less'
import { StripeCustomer } from '../../providers/ClientProvider/client/services/stripeCustomers/types'
import ContentLoader from '../../components/ContentLoader'
import MainImage from '../../components/MainImage/MainImage'
import Subscription from './containners/Subscription'
import { Client } from '../../providers/ClientProvider/client/services/clients/types'
import { StripeInvoices } from '../../providers/ClientProvider/client/services/stripeInvoices/types'

interface Props {
  stripeCustomers?: StripeCustomer
  onFinishBilling: (v: any) => void
  onFinishProfile: (v: any) => void
  onFinishPassword: (v: any) => void
  patchStatus: 'idle' | 'loading' | 'error' | 'success'
  activeAccount: boolean
  handleDeleteAccount: (v: any) => void
  columns: { [key: string]: any }[]
  invoices?: StripeInvoices[]
  client?: Client
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const { Title } = Typography

const MyAccountView = (props: Props) => {
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()

  return (
    <div className='my-account'>
      <MainImage image='account_yzdrit' style={{ objectPosition: '50% 80%' }} />
      <div className='my-account__title'>
        <Title>
          Zarządzaj swoim kontem
        </Title>
      </div>
      <Collapse accordion>
        <Collapse.Panel
          header={
            props.activeAccount
              ? <Typography.Text>Mój abonament</Typography.Text>
              : <Typography.Text>Mój abonament - <b>NIEAKTYWNE KONTO!</b></Typography.Text>
          }
          key='1'
        >
          <Subscription />
          <Title level={4} className='my-account__invoices-list'>Lista faktur</Title>
          <Table columns={props.columns} dataSource={props.invoices || []} />
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <Typography.Text>Moje dane do faktury</Typography.Text>
          }
          key='2'
        >
          <ContentLoader
            loading={!props.stripeCustomers}
          >
            <Form
              {...layout}
              name='update-billing-details'
              form={form1}
              onFinish={props.onFinishBilling}
              initialValues={{
                ...props.stripeCustomers,
                country: 'Polska',
                city: props.stripeCustomers?.address?.city,
                line1: props.stripeCustomers?.address?.line1,
                line2: props.stripeCustomers?.address?.line2,
                postal_code: props.stripeCustomers?.address?.postal_code
              }}
            >
              <Form.Item
                name='name'
                label='Nazwa'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Wprowadź swoje imię i nazwisko lub nazwę firmy.'
                  }
                ]}
              >
                <Input
                  placeholder='Imię i nazwisko / Nazwa Firmy'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>
              <Form.Item
                name='country'
                label='Kraj'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Wprowadź kraj.'
                  }
                ]}
              >
                <Input
                  placeholder='Kraj'
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='city'
                label='Miasto'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Wprowadź miasto.'
                  }
                ]}
              >
                <Input
                  placeholder='Miasto'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>
              <Form.Item
                name='line1'
                label='Adres 1'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Wprowadź kraj ulica, numer domu, numer mieszkania.'
                  }
                ]}
              >
                <Input
                  placeholder='Ulica, numer domu, numer mieszkania'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>
              <Form.Item
                name='line2'
                label='Adres 2'
              >
                <Input
                  placeholder='Adres 2'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>

              <Form.Item
                name='postal_code'
                label='Kod pocztowy'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Wprowadź kod pocztowy.'
                  }
                ]}
              >
                <Input
                  placeholder='00-000'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>
              <Form.Item
                name='taxId'
                label='NIP'
              >
                <Input
                  prefix='PL'
                  placeholder='NIP'
                  disabled={props.patchStatus === 'loading'}
                />
              </Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                disabled={props.patchStatus === 'loading'}
              >
                {props.patchStatus === 'loading' ? 'Trwa zapisywanie...' : 'Zapisz'}
              </Button>
            </Form>
          </ContentLoader>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <Typography.Text>Mój profil</Typography.Text>
          }
          key='3'
        >
          <Form
            {...layout}
            name='basic'
            form={form2}
            onFinish={props.onFinishProfile}
            initialValues={{
              remember: true,
              firstName: props.client?.firstName,
              lastName: props.client?.lastName,
              email: props.client?.email
            }}
          >
            <Form.Item
              name='firstName'
              label='Imię'
              rules={[{ required: true, message: 'Proszę wpisać imię' }]}
              className='my-account__form-item'
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='lastName'
              label='Nazwisko'
              rules={[{ required: true, message: 'Proszę wpisać nazwisko' }]}
              className='my-account__form-item'
            >
              <Input
              />
            </Form.Item>
            <Form.Item
              name='email'
              label='Adres E-Mail'
              rules={[
                {
                  type: 'email',
                  message: 'Proszę wpisać prawidłowy adres e-mail'
                },
                {
                  required: true,
                  message: 'Proszę wpisać adres e-mail'
                }]}
              className='my-account__form-item'
            >
              <Input
                disabled
              />
            </Form.Item>
            <Button type='primary' htmlType='submit'>
              ZAPISZ ZMIANY
            </Button>
          </Form>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <Typography.Text>Zmiana hasła</Typography.Text>
          }
          key='4'
        >
          <Form
            {...layout}
            name='changePassword'
            form={form3}
            initialValues={{ remember: true }}
            onFinish={props.onFinishPassword}
          >
            <Form.Item
              name='oldPassword'
              label='Obecne hasło'
              rules={[{ required: true, message: 'Proszę wpisać obecne hasło' }]}
              className='my-account__form-item'
            >
              <Input
                type='password'
              />
            </Form.Item>
            <Form.Item
              name='newPassword'
              label='Nowe hasło'
              rules={[{
                required: true,
                message: 'Hasło musi zawierać minimum 8 znaków, literę, cyfrę oraz wielką literę.',
                pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
              }]}
              hasFeedback
              className='my-account__form-item'
            >
              <Input
                type='password'
              />
            </Form.Item>
            <Form.Item
              name='repeatPassword'
              label='Potwierdź nowe hasło'
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Potwierdź hasło!'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Wprowadzone hasła nie są takie same.')
                  }
                })
              ]}
              className='my-account__form-item'
            >
              <Input
                type='password'
              />
            </Form.Item>
            <Button type='primary' htmlType='submit'>
              ZAPISZ ZMIANY
            </Button>
          </Form>
        </Collapse.Panel>
        <Collapse.Panel
          header={<Typography.Text>Zarządzaj kontem</Typography.Text>}
          key='5'
        >
          <Popconfirm
            title='Czy na pewno chcesz usunąć konto?'
            okText='Tak, usuń na stałe'
            cancelText='Anuluj'
            onConfirm={props.handleDeleteAccount}
          >
            <Button type='primary'>Usuń konto</Button>
          </Popconfirm>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default MyAccountView
