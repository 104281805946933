import { useFindQuery } from '../../query'

const useFAQs = () => {
  const {
    data: faqRes,
    status
  } = useFindQuery('faqs', {
    $limit: 50
  })

  return {
    faqList: faqRes?.data || [],
    status
  }
}
export default useFAQs
