import React from 'react'
import './videoPodcastItem.less'
import '../../layouts/AppLayout/appLayout.less'
import { Button, Col, Row, Typography } from 'antd'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import image from '../../images/video.jpg'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { Level } from '../../providers/ClientProvider/client/services/levels/types'
import {
  VideoPodcast
} from '../../providers/ClientProvider/client/services/videoPodcasts/types'

const { Title } = Typography

interface IProps {
  videoPodcast?: VideoPodcast
  level?: Level
  otherVideoPodcasts?: VideoPodcast[]
  videos?: Video[]
  clientId?: string | null
  activeAccount: boolean
  handleClick: (id: string) => void
  onSampleClick: (videoId: string) => void
  showSample: {[key: string]: boolean}
}

const VideoPodcastItemView = (props: IProps) => {
  return (
    <div className='videoPodcast-item'>
      <div className='videoPodcast-item__gradient'/>
      <div style={{ height: '200px', width: '100%' }}/>
      <Title level={1}><b>{props.videoPodcast?.name}</b><br/>VIDEO PODCAST {props.level?.name}</Title>
      <Row gutter={[{ xs: 16, md: 50 }, 16]}>
        <Col xs={24} md={12} lg={10}>
          <Title level={4} className='videoPodcast-item__description-title'>
            {props.videoPodcast ? props.videoPodcast.descriptionTitle : ''}
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: props.videoPodcast ? props.videoPodcast.description : ''
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={14}>
          <img src={props.videoPodcast?.image || image} alt='' width='100%' height='auto' className='videoPodcast-item__image'/>
        </Col>
      </Row>
      <Title level={1}>{props.videoPodcast?.slogan}</Title>
      {(!props.clientId || !props.activeAccount) &&
        <Typography.Paragraph>
          Aby obejrzeć cały film <Button size='small' type='primary' style={{ marginBottom: 10 }}>
          <Link to={appRoutes.login.path}>zaloguj się</Link>
          </Button> lub <Button size='small' type='primary'>
          <Link to={appRoutes.selectSubscription.path}>dołącz teraz</Link>
          </Button>
        </Typography.Paragraph>
      }
      <Row gutter={[16,16]} style={{ marginBottom: 50 }}>
        {props.videos?.map(video =>
          <Col xs={24} sm={12} md={8} key={video._id}>
            {(props.clientId && props.activeAccount)
              ? <div className='videoPodcast-item__video-container'>
                <div className='videoPodcast-item__video-inside-container'>
                  <ReactPlayer
                    className='player'
                    url={`https://info-prossto.wistia.com/medias/${video?.wisitaHashId}`}
                    width='100%'
                    height='100%'
                  />
                </div>
              </div>
              : <div className='wrapper'>
                <img
                  className='player'
                  src={video?.thumbnail?.url.replace('?image_crop_resized=200x120', '')}
                  alt={video?.name}
                  onClick={() => props.onSampleClick(video._id)}
                />
                {
                  props.showSample[video._id] && <div
                    className='overlay'
                  >
                    <div
                      className='overlay-content'
                    >
                      <ReactPlayer
                        url={video?.sampleVideo}
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                }
              </div>
            }
            <Title level={4}>{video.name}</Title>
          </Col>
        )
        }
      </Row>

      {props.otherVideoPodcasts && props.otherVideoPodcasts.length > 0 && (
        <>
          <Title level={2}>
            Zobacz inne Video Podcasty:
          </Title>
          <Row gutter={[16, 16]}>
            {props.otherVideoPodcasts?.map(videoPodcast =>
              <Col xs={12} md={8} key={videoPodcast._id}>
                <div onClick={() => props.handleClick(videoPodcast._id)}>
                  <img src={videoPodcast.image || image} alt='' width='100%'
                       height='auto' className='videoPodcast-item__image'/>
                  <Title level={4}>
                    {videoPodcast.name}
                  </Title>
                </div>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  )
}

export default VideoPodcastItemView
