import { Typography } from 'antd'
import React from 'react'

import './pageTitle.less'

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties

}

const PageTitle = (props: Props) => {
  return (
    <Typography.Title
      level={1}
      className='page-title page-title-main'
      style={props.style}
    >
      {props.children}
    </Typography.Title>
  )
}

export default PageTitle
