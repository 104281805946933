import React from 'react'
import './studio.less'
import '../../layouts/AppLayout/appLayout.less'
import { Button, Card, Col, Row, Typography } from 'antd'
import MainImage from '../../components/MainImage/MainImage'

const { Title, Paragraph } = Typography

const StudioView = () => {
  return (
    <div className='studio'>
      <MainImage image='studio_pzl8du' style={{ objectPosition: '50% 50%' }} className='studio__main-image'/>
      <Title level={1} className='studio__title'>
        Zapraszamy do studia w Warszawie
      </Title>
      <div className='studio__container'>
        <Paragraph className='studio__description'>
          Nasze studio Pilates wyposażone zostało w profesjonalny sprzęt
          Balanced Body zaprojektowany oryginalnie przez samego Josepha
          Pilatesa.<br/>
          Prowadzimy zajęcia na dużym sprzęcie: reformer, krzesło, wieża.
          Sprzęt jest twoim drugim nauczycielem – uczy ruchu, daje informację
          zwrotną, wspomaga poczucie ciała, kontroli, stabilizacji. Sprzęt z
          jednej strony utrudnia bo musimy zapanować nie tylko nad własnym
          ciałem ale i nad sprzętem, a z drugiej strony ułatwia pracę, gdyż
          pomaga wykonać i prowadzi ruch.<br/>
          Sprzętu nie oszukasz więc ćwiczenie musisz wykonać poprawnie, a to
          prowadzi do dużo lepszych i szybszych efektów.
        </Paragraph>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Card className='studio__card'>
              <ul>
                <li>
                  Potrzebujesz indywidualnej konsultacji?
                </li>
                <li>
                  Masz pytania, wątpliwości?
                </li>
                <li>
                  Chcesz połączyć ćwiczenia on-line z indywidualnymi treningami w studio?
                </li>
              </ul>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className='studio__card'>
              <Title level={3}>SKONTAKTUJ SIĘ</Title>
              <Title level={4}><a href='tel:+48602311445'>602 311 445</a></Title>
              <Title level={4}> <a href='mailto:info@pilates-online.pl'>info@pilates-online.pl</a></Title>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className='studio__card'>
              <Title level={3}>PRZYJDŹ NA ZAJĘCIA</Title>
              <Title level={4}>
                Studio ProSSto <br/>
                Ul. Kasprowicza 54<br/>
                01-871 Warszawa<br/>
                Metro Stare Bielany
              </Title>
            </Card>
          </Col>
        </Row>
      </div>
      <a href='https://www.prossto.pl/' target='_blank'>
        <Button size='large' type='primary' className='studio__button'>
          Dowiedz się więcej o Studio <br/> ProSSto w Warszawie
        </Button>
      </a>
    </div>
  )
}

export default StudioView
