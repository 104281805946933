import React from 'react'

import { Button, Form, Input, Collapse, Typography } from 'antd'
import {
  SendOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import './verifyEmail.less'
import { Client } from '../../providers/ClientProvider/client/services/clients/types'
import MainImage from '../../components/MainImage/MainImage'

const {
  Panel
} = Collapse
const { Paragraph, Text } = Typography

export interface SignUpViewProps {
  onFinish: (values: any) => void
  isSaving: boolean
  client?: Client
  resendVerificationEmail: () => void
  resendVerificationEmailLoading: boolean
  deleteAccount: (values: any) => void
  deleteAccountLoading: boolean
}
const VerifyEmailView = (props: SignUpViewProps) => {
  return (
    <div className='verify-email'>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Form
        name='verify-email'
        className='verify-email-form'
        onFinish={props.onFinish}
        size='large'
      >
        <Typography.Title level={2} style={{ textAlign: 'center' }}>Weryfikacja adresu email</Typography.Title>
        <Paragraph>Wysłaliśmy 6-cyfrowy kod weryfikacyjny na <Text strong>{props.client?.email}</Text>. Jeżeli nie jesteś w stanie go zlokalizować, sprawdź spam.</Paragraph>
        <Form.Item
          name='code'
          hasFeedback
          rules={[
            {
              required: true,
              len: 6,
              message: 'The code needs to have 6 characters.'
            }
          ]}
        >
          <Input
            placeholder='Kod weryfikacyjny'
          />
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={props.isSaving}
        >
          {props.isSaving ? 'Trwa weryfikacja...' : 'Zweryfikuj'}
        </Button>
        <Collapse
          className='verify-email-form__collapse'
        >
          <Panel header='Nie mogę zlokalizować wiadomości email, kod weryfikacyjny nie działa, kod weryfikacyjny wygasł?' key='1'>
            <Paragraph>
              Nie martw się! Spróbuj wysłać email weryfikacyjny jeszcze raz klikając w przycisk poniżej.
            </Paragraph>
            <Button
              type='primary'
              onClick={props.resendVerificationEmail}
              disabled={props.resendVerificationEmailLoading}
              icon={<SendOutlined />}
              size='middle'
            >
              {props.resendVerificationEmailLoading ? 'Trwa wysyłanie...' : 'Wyślij email weryfikacyjny.'}
            </Button>
          </Panel>
          <Panel header='Wprowadziłaś/eś niepoprawny adres email?' key='2'>
            <Paragraph>
              Nic nie szkodzi. Możesz usunąć konto klikając w przycisk poniżej i zarejestrować się na nowo.
            </Paragraph>
            <Button
              type='primary'
              danger
              onClick={props.deleteAccount}
              disabled={props.deleteAccountLoading}
              icon={<DeleteOutlined />}
              size='middle'
            >
              {props.deleteAccountLoading ? 'Trwa usuwanie...' : 'Usuń konto'}
            </Button>
          </Panel>
        </Collapse>
      </Form>
    </div>
  )
}

export default VerifyEmailView
