import React from 'react'
import { Result } from 'antd'
import MainImage from '../../components/MainImage/MainImage'
import './successResetPassword.less'

interface Props {
  onButtonPress: () => void
  saving: boolean
}

const SuccessResetPasswordView = (props: Props) => {
  return (
    <div className='success-reset-password'>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Result
        status='success'
        title='Na podany adres email wysłano wiadomość resetującą hasło'
        subTitle='Kliknij w link w wiadomości aby dokończyć proces zmiany hasła'
      />
    </div>
  )
}

export default SuccessResetPasswordView
