import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { RootState } from '../../redux/reducers'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDeleteMutation } from '../../query'
import { appRoutes } from '../../containers/Router/routes'

const useDeleteAccount = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)

  const history = useHistory()

  const [removeClient, { status }] = useDeleteMutation('clients')

  const deleteAccount = useCallback(async (values: any) => {
    if (!clientId) {
      return message.error('User not specified')
    }
    await removeClient(clientId)
    message.info(`Konto usunięte`)
    history.push(appRoutes.logOut.path)
  }, [clientId])

  return {
    deleteAccountLoading: status === 'loading',
    deleteAccount
  }
}
export default useDeleteAccount
