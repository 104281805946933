import React from 'react'

import ContactHelpView from './ContactHelpView'
import useContactHelp from './useContactHelp'

const ContactHelp = () => {
  const state = useContactHelp()

  return (
    <ContactHelpView {...state} />
  )
}

export default ContactHelp
