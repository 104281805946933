import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import configureStore from '../../redux/store'

import Router from '../Router'

import './App.css'

const App = () => {
  return (
    <ReduxProvider store={configureStore()}>
      <Router />
    </ReduxProvider>
  )
}

export default App
