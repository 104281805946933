import React from 'react'

import { Favorite } from '../../providers/ClientProvider/client/services/favorites/types.d'
import { Planned } from '../../providers/ClientProvider/client/services/planned/types'
import { Video } from '../../providers/ClientProvider/client/services/videos/types'
import { Watched } from '../../providers/ClientProvider/client/services/watchedVideos/types'
import useVideoCard from '../useVideoCard'
import VideoCardView from './VideoCardView'

interface Props {
  video: Video
  favorite?: Favorite
  watched?: Watched
  planned?: Planned
}

const VideoCard = (props: Props) => {
  const state = useVideoCard(props.video, props.favorite, props.watched, props.planned)

  return (
    <VideoCardView {...props} {...state} />
  )
}

export default VideoCard
