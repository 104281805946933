export interface AppLayoutState {
  breadcrumb: (string | {
    to: string
    name: string
  })[]
  leftMenuCollapsed: boolean
  leftMenuDrawerOpen: boolean
  authenticationInProgress: boolean
}

export const SET_APP_LAYOUT = 'SET_APP_LAYOUT'

export interface SetAppLayoutAction {
  type: typeof SET_APP_LAYOUT,
  payload: Partial<AppLayoutState>
}

export type AppLayoutActionTypes = SetAppLayoutAction
