export interface LoginState {
  saving: boolean
}

export const SET_LOGIN = 'SET_LOGIN'

export interface SetLoginAction {
  type: typeof SET_LOGIN,
  payload: Partial<LoginState>
}

export type LoginActionTypes = SetLoginAction
