import React from 'react'

import { Button, Card, Checkbox, Col, Form, Input, Row, Typography } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

import './signUp.less'
import { Link } from 'react-router-dom'
import config from '../../config'
import MainImage from '../../components/MainImage/MainImage'
import { appRoutes } from '../../containers/Router/routes'
import { StripePlan } from '../../providers/ClientProvider/client/services/stripePlans/types'

export interface SignUpViewProps {
  onFinish: (values: any) => void
  isSaving: boolean
  subscriptionPlanId?: string
  subscriptionPlan?: StripePlan
}

const SignUpView = (props: SignUpViewProps) => {
  return (
    <div className='sign-up'>
      <MainImage image='account_yzdrit' small  style={{
        objectPosition: '50% 57%',
      }}/>
      <div
        className='sign-up__container'
        style={{
          marginTop: 50,
          marginBottom: 50
        }}
      >
        <Row gutter={24} style={{ margin: 0 }}>
          <Col xs={24} md={12}>
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey}>
              <Form
                name='sign-up'
                className='sign-up__form'
                initialValues={{
                  subscriptionPlanId: props.subscriptionPlanId
                }}
                onFinish={props.onFinish}
                size='large'
              >
                <Card>
                  <Typography.Title level={2} style={{ textAlign: 'center' }}>Rejestracja</Typography.Title>
                  <Form.Item
                    name='firstName'
                    hasFeedback
                    rules={[{
                      required: true,
                      message: 'Pole wymagane.'
                    }]}
                  >
                    <Input
                      placeholder='Imię'
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name='lastName'
                    hasFeedback
                    rules={[{
                      required: true,
                      message: 'Pole wymagane.'
                    }]}
                  >
                    <Input
                      placeholder='Nazwisko'
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name='email'
                    hasFeedback
                    rules={[{
                      required: true,
                      type: 'email',
                      message: 'Niepoprawny email.'
                    }]}
                  >
                    <Input
                      placeholder='Email'
                      type='email'
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    hasFeedback
                    rules={[{
                      required: true,
                      message: 'Hasło musi zawierać minimum 8 znaków, literę, cyfrę oraz wielką literę.',
                      pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
                    }]}
                  >
                    <Input
                      placeholder='Hasło'
                      type='password'
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name='confirmPassword'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Potwierdź email!'
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject('Wprowadzone hasła nie są takie same.')
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder='Potwierdź hasło'
                      type='password'
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name='accountConditions'
                    valuePropName='checked'
                    rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Zgoda wymagana.') }]}
                  >
                    <Checkbox>Akceptuję <Link to={appRoutes.rules.path}>Regulamin Założenia Konta</Link>.</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name='salesConditions'
                    valuePropName='checked'
                    rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Zgoda wymagana.') }]}
                  >
                    <Checkbox>Akceptuję <Link to={appRoutes.rules.path}>Regulamin sprzedaży</Link>.</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name='newsletterAgreement'
                    valuePropName='checked'
                  >
                    <Checkbox>Wyrażam zgodę na wysyłkę newslettera zgodnie z <Link to={appRoutes.privacy.path}>Polityką prywatności</Link>.</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{ display: 'none' }}
                    name='isHuman'
                    rules={[{ required: true }]}
                  >
                    <span />
                  </Form.Item>
                  <Form.Item
                    style={{ height: 0 }}
                    name='subscriptionPlanId'
                    rules={[{ required: true }]}
                  >
                    <span />
                  </Form.Item>
                  <Form.Item
                    shouldUpdate
                    style={{ display: 'none' }}
                  >
                    {({ setFieldsValue }) => (
                      <GoogleReCaptcha
                        onVerify={token => setFieldsValue({ isHuman: !!token })}
                      />
                    )}
                  </Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={props.isSaving}
                  >
                    {props.isSaving ? 'Zapisywanie...' : 'Zarejestruj się'}
                  </Button>
                </Card>
              </Form>
            </GoogleReCaptchaProvider>
          </Col>
          <Col xs={24} md={12}>
            <Card className='sign-up__subscription'>
              <Typography.Title level={4}>TWÓJ ABONAMENT</Typography.Title>
              <Typography.Title level={2}>{props.subscriptionPlan ? props.subscriptionPlan.amount / 100 : '...'} zł</Typography.Title>
              <ul style={{ marginTop: '24px' }}>
                {props.subscriptionPlan?.interval === 'year' ?
                <li>
                  Płatność pobierana automatycznie co rok
                </li>                
                : 
                <li>
                  Płatność pobierana automatycznie co miesiąc
                </li>}
                <li>
                  Możesz anulować w dowolnym momencie
                </li>
                <li>
                  Najwyższe bezpieczeństwo płatności SSL.
                </li>
              </ul>
            </Card>
            <Card className='sign-up__subscription'>
              <Typography.Title level={4}>Co otrzymasz w ramach abonamentu?</Typography.Title>
              <ul>
                <li>
                  Nieograniczony dostęp do wszystkich materiałów na platformie - 24h/7 dni
                </li>
                <li>
                  Prowadzenie krok po kroku
                </li>
                <li>
                  Dostęp do zamkniętej Grupy Motywacyjnej na facebook’u tylko dla subskrybentów
                </li>
                <li>
                  Spotkania na żywo, miesięczne plany praktyki, motywację, inspirację, wsparcie i wymianę doświadczeń w Grupie
                </li>
                <li>
                  Indywidualne doradztwo, opiekę i  stały kontakt w przypadku szczególnych potrzeb
                </li>
                <li>
                  Regularnie dodawane filmy, programy, wyzwania.
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SignUpView
