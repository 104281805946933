export interface AppState {
  clientInitialized: boolean
  authenticated: boolean
  clientId: string | null
  activeAccount: boolean
}

export const SET_APP = 'SET_APP'

export interface SetAppAction {
  type: typeof SET_APP,
  payload: Partial<AppState>
}

export type AppActionTypes = SetAppAction
