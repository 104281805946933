import useGetVideoInfos from '../../hooks/useGetVideoInfos/useGetVideoInfos'
import { useFindQuery } from '../../query'

const useMyFavorites = () => {
  const getVideoInfos = useGetVideoInfos({
    onPlannedSuccess: async () => { await videosRefetch() }
  })

  const {
    refetch: videosRefetch,
    data: videosPaginated,
    status: videoStatus
  } = useFindQuery('videos', {
    _id: getVideoInfos.plannedList?.map((item) => item.videoId),
  }, {
    manual: true
  })

  return {
    videosList: videosPaginated?.data,
    videoStatus,
    ...getVideoInfos,
  }
}

export default useMyFavorites
