import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import { useCreateMutation } from '../../query'
import { useEffect } from 'react'

const useContactHelp = () => {
  const history = useHistory()
  const [createEmail, { status }] = useCreateMutation('contactForm')

  useEffect(() => {
    if (status === 'success') {
      message.success('Dziękujemy za wiadomość, postaramy się jak najszybciej odpowiedzieć.')
      history.push(appRoutes.home.path)
    }
    if (status === 'error') {
      message.error('Wystąpił błąd. Prosimy, spróbuj ponownie.')
    }
  }, [history, status])

  const onFinish = async (values: { [key: string]: any }) => {
    await createEmail(values)
  }

  return {
    onFinish
  }
}
export default useContactHelp
