import { LoginActionTypes, LoginState, SET_LOGIN } from './types'

const INITIAL_STATE: LoginState = {
  saving: false
}

export const loginState = (
  state = INITIAL_STATE,
  action: LoginActionTypes
): LoginState => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
