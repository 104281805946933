import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { useGetQuery } from '../../query'

const useHome = () => {
  const history = useHistory()
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const {
    data: user
  } = useGetQuery('users', clientId)

  const handleCTA = () => {
    history.push('/select-subscription')
  }
  return {
    handleCTA,
    user
  }
}
export default useHome
