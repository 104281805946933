import React from 'react'

import './PageContent.less'

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

const PageContent = (props: Props) => {
  return (
    <div
      className={`page-content ${props.className}`}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

export default PageContent
