interface IConfig {
  jwtStorageKey: string
  stripePublicKey: string
  apiURL: string
  recaptchaKey: string
}

let config: IConfig = {
  jwtStorageKey: 'auth',
  stripePublicKey: 'missing',
  apiURL: 'missing',
  recaptchaKey: 'missing'
}

const initConfig = () => {
  if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL not specified')
  }
  if (!process.env.REACT_APP_JWT_STORAGE_KEY) {
    throw new Error('REACT_APP_JWT_STORAGE_KEY not specified')
  }
  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    throw new Error('REACT_APP_STRIPE_PUBLIC_KEY not specified')
  }
  if (!process.env.REACT_APP_RECAPTCHA_KEY) {
    throw new Error('REACT_APP_RECAPTCHA_KEY not specified')
  }
  config = {
    ...config,
    jwtStorageKey: process.env.REACT_APP_JWT_STORAGE_KEY,
    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    apiURL: process.env.REACT_APP_API_URL,
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY
  }
}

initConfig()

export default config
