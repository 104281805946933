import React from 'react'

import MyAccountView from './MyAccountView'
import useMyAccount from './useMyAccount'

const MyAccount = () => {
  const state = useMyAccount()

  return (
    <MyAccountView {...state} />
  )
}

export default MyAccount
