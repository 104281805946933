import { useSelector } from "react-redux"
import { Favorite } from "../../providers/ClientProvider/client/services/favorites/types.d"
import { Planned } from "../../providers/ClientProvider/client/services/planned/types"
import { Watched } from "../../providers/ClientProvider/client/services/watchedVideos/types"
import { useFindQuery } from "../../query"
import { RootState } from "../../redux/reducers"

type GetVideoInfosProps = {
    limit?: number,
    onFavoritesSuccess?: ((data: any) => void) | undefined
    onWatchedSuccess?: ((data: any) => void) | undefined
    onPlannedSuccess?: ((data: any) => void) | undefined
}

type videoStatus = "loading" | "error" | "success"

export interface GetVideoInfosResponse {
    favoritesList?: Favorite[],
    favoriteStatus: videoStatus,
    watchedList?: Watched[],
    watchedStatus: videoStatus,
    plannedList?: Planned[],
    plannedStatus: videoStatus,
}

const useGetVideoInfos = ({
    limit = 100,
    onFavoritesSuccess = () => {},
    onWatchedSuccess = () => {},
    onPlannedSuccess = () => {},
}: GetVideoInfosProps) => {
    const clientId = useSelector((state: RootState) => state.appState.clientId)

    const {
        data: favoritesPaginated,
        status: favoriteStatus
    } = useFindQuery('favoriteVideos',
        clientId
            ? {
                clientId: clientId,
                $limit: limit,
            }
            : false, {
        onSuccess: onFavoritesSuccess
    }
    )
    
    const {
        data: watchedPaginated,
        status: watchedStatus
    } = useFindQuery('watchedVideos',
        clientId
            ? {
                clientId: clientId,
                $limit: limit,

            }
            : false, {
        onSuccess: onWatchedSuccess
    }
    )
    
    const {
        data: plannedPaginated,
        status: plannedStatus
    } = useFindQuery('plannedVideos',
        clientId
            ? {
                clientId: clientId,
                $limit: limit,

            }
            : false, {
        onSuccess: onPlannedSuccess
    }
    )

    return {
        favoritesList : favoritesPaginated?.data,
        favoriteStatus,
        watchedList: watchedPaginated?.data,
        watchedStatus,
        plannedList: plannedPaginated?.data,
        plannedStatus,
    }
}

export default useGetVideoInfos
