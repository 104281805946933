import React from 'react'
import './home.less'
import '../../layouts/AppLayout/appLayout.less'
import symbol1 from '../../images/symbol1.png'
import symbol2 from '../../images/symbol2.png'
import symbol3 from '../../images/symbol3.png'
import symbol4 from '../../images/symbol4.png'
import symbol5 from '../../images/symbol5.png'
import { Button, Col, Row, Typography } from 'antd'
import MainImage from '../../components/MainImage/MainImage'
import PageContent from '../../components/PageContent'
import PageTitle from '../../components/Headers/PageTitle'
import PageSubheader from '../../components/Headers/PageSubheader'
import { User } from '../../providers/ClientProvider/client/services/users/types'
import ReactPlayer from 'react-player'
// @ts-ignore
import Img from 'react-cloudinary-lazy-image'

const { Title, Paragraph } = Typography

interface IProps {
  handleCTA: () => void
  user?: User
}

const HomeView = (props: IProps) => {
  return (
    <div className='home'>
      <MainImage image='front_page_tvxast' style={{
        objectPosition: '50% 50 s%'
      }} />
      <PageContent className='home__title'>
        <PageTitle>Zadbaj o swój kręgosłup</PageTitle>
        <PageSubheader>Gdzie chcesz i kiedy tylko możesz</PageSubheader>
        {props.user
          ? null
          : <div className='home__billboard-section'>
            <Button
              className='home__billboard-button'
              type='primary'
              size='large'
              onClick={props.handleCTA}
            >
              Dołącz już teraz
            </Button>
            <Title level={4}>79 zł / mies lub 699 zł / rok</Title>
          </div>}
      </PageContent>
      <div className={`home__section home__trailer-section ${props.user && 'no-button'}`}>
        <Title className='home__section__title'>
          Pilates z ProSSto to bezpieczny i funkcjonalny ruch.<br /> To zdrowie i sprawność na co dzień.
        </Title>
        <Row gutter={[{ sm: 16, md: 50 }, 16]} style={{ width: '100%' }}>
          <Col xs={24} sm={12}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='home__player'
                  url={`https://info-prossto.wistia.com/medias/n09slhyzdw`}
                  width='99.5%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__trailer-section__subtitle' level={3}>
              Dlaczego warto do nas dołączyć?
            </Title>
          </Col>
          <Col xs={24} sm={12}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='player'
                  url={`https://info-prossto.wistia.com/medias/632wlugp0h`}
                  width='99.5%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__trailer-section__subtitle' level={3}>
              Poznaj Pilates z Prossto
            </Title>
          </Col>
        </Row>
      </div>

      <div className='home__section home__levels-section'>
        <Title className='home__section__title'>
          Zobacz co przygotowałyśmy dla Ciebie na platformie!
        </Title>
        <Paragraph className='home__level-section__text' style={{ marginBottom: 0 }}>
          <li>Wybieraj filmy i programy dostosowane do Twoich potrzeb.</li>
          <li>Ćwicz ze specjalistycznymi programami zdrowotnymi.</li>
          <li>Dobieraj cel ćwiczeń, poziom zaawansowania i czas trwania lekcji.</li>
          <li>Oferujemy indywidualne doradztwo, opiekę i stały kontakt w przypadku szczególnych potrzeb.</li>
        </Paragraph>
        <Title className='home__section__title'>
          Dołącz do zamkniętej GRUPY MOTYWACYJNEJ <br/>na facebook’u tylko dla subskrybentów!
        </Title>
        <Paragraph className='home__level-section__text' style={{ marginBottom: 36 }}>
          <li>Tu odbywają się spotkania na żywo.</li>
          <li>Tu poznasz osoby podobne do siebie, na których wsparcie i wymianę doświadczeń możesz liczyć.</li>
          <li>Tu otrzymasz miesięczne plany praktyki, motywację do ćwiczeń i inspiracje do dalszego rozwoju.</li>
        </Paragraph>
        <Row gutter={[16, 16]} className='home__level-section__row'>
          <Col xs={12} md={6}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='player'
                  url={`https://info-prossto.wistia.com/medias/zhp50kxz1g`}
                  width='100%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__level-section__subtitle' level={3}>
              Początkujący
            </Title>
          </Col>
          <Col xs={12} md={6}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='player'
                  url={`https://info-prossto.wistia.com/medias/ext3td814q`}
                  width='100%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__level-section__subtitle' level={3}>
              Zaawansowani
            </Title>
          </Col>
          <Col xs={12} md={6}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='player'
                  url={`https://info-prossto.wistia.com/medias/el8gbpvwz5`}
                  width='100%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__level-section__subtitle' level={3}>
              Stretching
            </Title>
          </Col>
          <Col xs={12} md={6}>
            <div className='home__video-container'>
              <div className='home__video-inside-container'>
                <ReactPlayer
                  className='player'
                  url={`https://info-prossto.wistia.com/medias/6ucmjc2k80`}
                  width='100%'
                  height='100%'
                />
              </div>
            </div>
            <Title className='home__level-section__subtitle' level={3}>
              Programy zdrowotne
            </Title>
          </Col>
        </Row>
        {props.user
          ? null
          : <>
            <Button
              className='home__billboard-button'
              type='primary'
              size='large'
              onClick={props.handleCTA}
            >
              Dołącz już teraz
            </Button>
            <Title level={4}>79 zł / mies lub 699 zł / rok</Title>
          </>}
      </div>

      <div className='home__section home__info-section'>
        <Title className='home__section__title'>
          Na Pilates-online znajdziesz wszystko czego <br /> potrzebujesz aby zadbać o siebie i swój kręgosłup!
        </Title>
        <Row gutter={[16, 16]} className='home__info-section__row'>
          <Col xs={24} md={12} lg={5}>
            <div className='home__info-section__card'>
              <img src={symbol1} alt='symbol1' />
              <Title level={4}>
                Twój nieograniczony dostęp
              </Title>
              <ul>
                <li>Lekcje i programy na 3 poziomach zaawansowania</li>
                <li>Regularnie dodawane nowości</li>
                <li>Miesięczne plany trenigowe</li>
                <li>Dostęp 24h / 7 dni w tygodniu</li>
                <li>Ćwiczysz kiedy chcesz i masz czas</li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={5}>
            <div className='home__info-section__card'>
              <img src={symbol2} alt='symbol1' />
              <Title level={4}>
                Pilates skrojony pod Twoje potrzeby
              </Title>
              <ul>
                <li>Ćwiczenia dla osób początkujących</li>
                <li>Zaawansowane sesje pilatesu klasycznego</li>
                <li>Programy zdrowotne dla osób ze schorzeniami kręgosłupa, bólami pleców</li>
                <li>Ćwiczenia zdrowotne pod kątem ograniczeń ruchu barków, bioder</li>
                <li>Programy wzmacniające CORE – mięśnie głębokie</li>
                <li>Sesje stretchingowe</li>
                <li>Rozluźnianie powięziowe</li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={8} lg={5}>
            <div className='home__info-section__card'>
              <img src={symbol3} alt='symbol1' />
              <Title level={4}>
                Najwyższa jakość z myślą o Twoim bezpieczeństwie
              </Title>
              <ul>
                <li>Wiedza, jakość, profesjonalizm</li>
                <li>Bezpieczeństwo ćwiczących</li>
                <li>Dokładne instrukcje i wskazówki</li>
                <li>Prowadzenie krok po kroku aby osiągnąć najlepsze efekty</li>
                <li>Sekcja video podcastów dla edukacji o zdrowiu i zdrowym kręgosłupie</li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={8} lg={5}>
            <div className='home__info-section__card'>
              <img src={symbol4} alt='symbol1' />
              <Title level={4}>
                Masz wszystko pod kontrolą
              </Title>
              <ul>
                <li>Ćwiczysz kiedy masz ochotę i czas – ty decydujesz</li>
                <li>Zarządzasz swoim kontem i płatnościami</li>
                <li>Możesz anulować i wznowić subskrypcję w dowolnym momencie</li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={8} lg={4}>
            <div className='home__info-section__card'>
              <img src={symbol5} alt='symbol1' />
              <Title level={4}>Gwarantujemy</Title>
              <ul>
                <li>Zdrowy i elastyczny kręgosłup</li>
                <li>Sprawność i funkcjonalność na co dzień</li>
                <li>Zniwelowanie bólu i napięć pleców</li>
                <li>Prawidłową postawę</li>
                <li>Siłę i wytrzymałość</li>
                <li>Energię i lepsze samopoczucie na co dzień</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      <div className='home__section home__preview-section'>
        <div className='home__preview-section__image'>
          <Img
            cloudName={'prossto-agata-jedrzejczuk'}
            imageName='frontend_images/mockup_ncyab0'
            fluid={{
              maxWidth: 1200
            }}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 100%'
            }}
          />
        </div>

        {props.user
          ? null
          : <>
            <Button
              className='home__billboard-button'
              type='primary'
              size='large'
              onClick={props.handleCTA}
            >
              Dołącz już teraz
            </Button>
            <Title level={4}>79 zł / mies lub 699 zł / rok</Title>
          </>}
      </div>

    </div >
  )
}

export default HomeView
