import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { useGetQuery } from '../../query'
import { setApp } from '../../containers/App/redux/actions'
import { useEffect } from 'react'

const useSuccess = () => {
  const dispatch = useDispatch()
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const { data: client } = useGetQuery('clients', clientId)
  useEffect(() => {
    if (!client) return
    dispatch(setApp({
      activeAccount: true
    }))
  }, [client])
}

export default useSuccess
