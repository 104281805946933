import React from 'react'
import { Form, Input, Button, Checkbox, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import './resetPassword.less'
import { Link } from 'react-router-dom'
import MainImage from '../../components/MainImage/MainImage'
import { appRoutes } from '../../containers/Router/routes'

interface ResetPasswordViewProps {
  onFinish: (values: any) => void
  sending: boolean
}

const ResetPasswordView = (props: ResetPasswordViewProps) => {
  return (
    <div className='reset-password'>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Form
        name='reset-password'
        className='reset-password-form'
        onFinish={props.onFinish}
        size='large'
      >
        <Typography.Title level={2} style={{ textAlign: 'center' }}>Przypomnij hasło</Typography.Title>
        <Form.Item
          name='email'
          rules={[{ required: true, type: 'email', message: 'Wpisz adres email.' }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='Email'
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='reset-password-form-button'
            disabled={props.sending}
          >
            {props.sending ? 'Trwa wysyłanie' : 'Przypomnij hasło'}
          </Button>
          <Link to={appRoutes.login.path}>Pamiętam hasło</Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ResetPasswordView
