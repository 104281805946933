import React from 'react'

import '../../layouts/AppLayout/appLayout.less'
import ContentLoader from '../../components/ContentLoader'
import MainImage from '../../components/MainImage/MainImage'
import './privacyPolicy.less'
import { Card, Typography } from 'antd'

interface IPrivacyPolicyProps {
  status: 'loading' | 'error' | 'success'
  html: string
}
const { Title } = Typography

const PrivacyPolicyView = (props: IPrivacyPolicyProps) => {
  return (
    <div className='privacy'>
      <MainImage image='front_page_uaijnv' />
      <ContentLoader loading={props.status === 'loading'}>
        <Card
          className='privacy__container'
        >
          <Title level={1}>
            Polityka prywatności
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: props.html
            }}
          />
        </Card>
      </ContentLoader>
    </div>
  )
}

export default PrivacyPolicyView
