import React from 'react'
import SuccessVerifyEmailView from './SuccessVerifyEmailView'
import useSuccessVerifyEmail from './useSuccessVerifyEmail'

const SuccessVerifyEmail = () => {
  const state = useSuccessVerifyEmail()
  return (
    <SuccessVerifyEmailView
      {...state}
    />
  )
}

export default SuccessVerifyEmail
