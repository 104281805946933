import React from 'react'

import FAQsView from './FAQsView'
import useFAQs from './useFAQs'

const FAQ = () => {
  const state = useFAQs()

  return (
    <FAQsView {...state} />
  )
}

export default FAQ
