import React from 'react'

import useMyPlanned from './useMyPlanned'
import MyPlannedView from './MyPlannedView'

const MyPlanned = () => {
  const state = useMyPlanned()

  return (
    <MyPlannedView {...state} />
  )
}

export default MyPlanned
