import React from 'react'

import VideoItemView from './VideoItemView'
import useVideoCard from './useVideoCard'
import { useParams } from 'react-router-dom'
import useVideoItem from './useVideoItem'

const VideoItem = () => {
  const { id } = useParams<{ id: string }>()

  const props = useVideoItem(id)
  const state = useVideoCard(id)

  return (
    <VideoItemView {...props} {...state} />
  )
}

export default VideoItem
