import { AppActionTypes, AppState, SET_APP } from './types'

const INITIAL_STATE: AppState = {
  clientInitialized: false,
  authenticated: false,
  clientId: null,
  activeAccount: false
}

export const appState = (
  state = INITIAL_STATE,
  action: AppActionTypes
): AppState => {
  switch (action.type) {
    case SET_APP:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
