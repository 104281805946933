import { VideosActionTypes, VideosState, SET_VIDEOS } from './types'

const INITIAL_STATE: VideosState = {
  loading: false,
  videoIds: [],
  total: 0,
  currentPage: 1,
  targetIds: [],
  levelIds: [],
  equipmentIds: []
}

export const videos = (
  state = INITIAL_STATE,
  action: VideosActionTypes
): VideosState => {
  switch (action.type) {
    case SET_VIDEOS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
