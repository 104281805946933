import React from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'

const NoMatchView = () => {
  return (
    <Result
      style={{
        marginTop: 200
      }}
      status='404'
      title='404'
      subTitle='Przepraszamy, strona nie istnieje.'
      extra={
        <Link
          to={appRoutes.home.path}
        >
          Powrót do domu
        </Link>
      }
    />
  )
}

export default NoMatchView
