import React from 'react'

import VideosListView from './VideosListView'
import useVideosList from './useVideosList'

const VideosList = () => {
  const state = useVideosList()

  return (
    <VideosListView {...state} />
  )
}

export default VideosList
