import { useDispatch } from 'react-redux'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import ClientContext from '../../providers/ClientProvider/client'
import { setApp } from '../../containers/App/redux/actions'
import { useCreateMutation, useGetQuery } from '../../query'
import { appRoutes } from '../../containers/Router/routes'
import qs from 'qs'

const useSignUp = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const apiClient = useContext(ClientContext)

  const [createClient, { status }] = useCreateMutation('clients')

  const location = useLocation()

  let {
    subscriptionPlanId
  } = useMemo(
    () => qs.parse(location.search.substr(1)),
    [location.search]
  )

  const {
    data: subscriptionPlan,
    status: subscriptionPlanStatus,
    failureCount
  } = useGetQuery('stripePlans', subscriptionPlanId)

  useEffect(() => {
    if (!subscriptionPlanId || failureCount) {
      history.push(appRoutes.selectSubscription.path)
    }
  }, [subscriptionPlanId, failureCount])

  const onFinish = useCallback(async (values: any) => {
    const client1 = await createClient(values)

    if (!client1) return
    try {
      const {
        user
      } = await apiClient.authenticate({
        strategy: 'local',
        email: values.email,
        password: values.password
      })
      dispatch(
        setApp({
          clientId: user._id
        })
      )
    } catch (e) {
      // @ts-ignore
      message.error(e.message)
    }

    history.push(appRoutes.verifyEmail.path)
    message.info(`Email z kodem weryfikacyjnym został wysłany na ${client1.email}`)
  }, [])

  return {
    isSaving: status === 'loading',
    onFinish,
    subscriptionPlan,
    subscriptionPlanId
  }
}
export default useSignUp
