import { useFindQuery, useGetQuery } from '../../query'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { useEffect, useState } from 'react'

const useProgramItem = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const activeAccount = useSelector((state: RootState) => state.appState.activeAccount)

  const { data: program } = useGetQuery('programs', id)
  const { data: level } = useGetQuery('levels', program?.levelId)
  const { data: videos } = useFindQuery('videos', {
    programId: id,
    $limit: 50,
    $sort: { order: 'ASC' }
  })

  const { data: otherPrograms } = useFindQuery('programs', {
    _id: { $ne: id },
    $limit: 3
  })

  const handleClick = (id: string) => {
    history.push(`/programs/${id}`)
  }

  const [showSample, setShowSample] = useState({})

  useEffect(() => {
    const initialShow: {[key: string]: boolean} = {}
    videos?.data.map(video => {
      initialShow[video._id] = false
    })
    setShowSample(initialShow)
  }, [videos?.data])

  const onSampleClick = (videoId: string) => {
    setShowSample({ ...showSample, [videoId]: true })
  }

  return {
    program,
    level,
    otherPrograms: otherPrograms?.data,
    videos: videos?.data,
    clientId,
    activeAccount,
    handleClick,
    onSampleClick,
    showSample
  }
}
export default useProgramItem
