import { SET_VIDEO, VideoActionTypes, VideoState } from './types'

const INITIAL_STATE: VideoState = {
  id: 0
}

export const video = (
  state = INITIAL_STATE,
  action: VideoActionTypes
): VideoState => {
  switch (action.type) {
    case SET_VIDEO:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
