import React from 'react'
import { Button, Card, Col, Divider, Row, Typography } from 'antd'
import MainImage from '../../components/MainImage/MainImage'
import './selectSubscription.less'
import { Link } from 'react-router-dom'
import { appRoutes } from '../../containers/Router/routes'
import qs from 'qs'
import PageTitle from '../../components/Headers/PageTitle'
import PageSubheader from '../../components/Headers/PageSubheader'
import PageContent from '../../components/PageContent'

interface Props {
  subscriptionPlan1?: { [key: string]: any }
  subscriptionPlan2?: { [key: string]: any }
}

const SelectSubscriptionView = (props: Props) => {
  return (
    <div className='select-subscription'>
      <MainImage image='account_yzdrit' style={{
        objectPosition: '50% 150%'
      }} />
      <PageContent>
        <PageTitle>Zacznij ćwiczyć Pilates już dziś</PageTitle>
        <PageSubheader
          style={{
            marginBottom: 100
          }}
        >wzmocnij swoje ciało i uelastycznij kręgosłup z pilates online</PageSubheader>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12}>
            <Card className='select-subscription__subscription'>
              <Typography.Title level={4}>MIESIĘCZNY <br /> ABONAMENT</Typography.Title>
              <Divider style={{ border: '1px solid black' }} />
              <Typography.Title level={2}>{((props.subscriptionPlan1?.amount || 79) / 100).toFixed(0)} zł</Typography.Title>
              <Link
                to={`${appRoutes.signUp.path}/?${qs.stringify({
                  subscriptionPlanId: props.subscriptionPlan1?.id
                })}`}
              >
                <Button
                  type='primary'
                  className='select-subscription__select-button'
                >
                  WYBIERAM
                </Button>
              </Link>
            </Card>
          </Col>
          <Col xs={24} sm={12}>
            <Card className='select-subscription__subscription'>
              <Typography.Title level={4}>ROCZNY <br /> ABONAMENT</Typography.Title>
              <Divider style={{ border: '1px solid black' }} />
              <Typography.Title level={2}>{((props.subscriptionPlan2?.amount || 699) / 100).toFixed(0)} zł</Typography.Title>
              <Typography.Text>2 miesiące gratis!</Typography.Text>
              <Link
                to={`${appRoutes.signUp.path}/?${qs.stringify({
                  subscriptionPlanId: props.subscriptionPlan2?.id
                })}`}
              >
                <Button
                  type='primary'
                  className='select-subscription__select-button'
                >
                  WYBIERAM
                </Button>
              </Link>
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Card className='select-subscription__description'>
              <ul>
                <li >
                  <Typography.Title level={4}>
                    Nieograniczony dostęp do wszystkich materiałów na platformie
                  </Typography.Title>
                </li>
                <li>
                  <Typography.Title level={4}>
                    ponad 100 lekcji, specjalistyczne PROGRAMY ZDROWOTNE, video podcasty
                  </Typography.Title>
                </li>
                <li>
                  <Typography.Title level={4}>
                    3 poziomy zaawansowania, lekcje od 15 do 60 MINUT
                  </Typography.Title>
                </li>
                <li>
                  <Typography.Title level={4}>
                    Regularne WSPARCIE w zamkniętej Grupie Motywacyjnej na facebook’u
                  </Typography.Title>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </PageContent>
    </div>
  )
}

export default SelectSubscriptionView
