import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import WebFont from 'webfontloader'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TDV3TCM'
}

TagManager.initialize(tagManagerArgs)
WebFont.load({
  google: {
    families: ['Sen', 'Muli', 'Lato', 'sans-serif']
  }
})

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
