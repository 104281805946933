function getPlanId (clientSubscriptionPlanId: string) {
  switch (clientSubscriptionPlanId) {
    case 'price_1H837ZA9eQ3FzEF5FJW7owGj': // old monthly subscription
      return 'price_1QHkx8A9eQ3FzEF5SzHx8z4z' // new monthly subscription
    case 'price_1H837aA9eQ3FzEF5SWsPdwAE': // old yearly subscription
      return 'price_1QHkxKA9eQ3FzEF5hnRlx431' // new yearly subscription
    default:
      return clientSubscriptionPlanId
  }
}

export default getPlanId
