import { Typography } from 'antd'
import React from 'react'

import './pageSubheader.less'

interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
}

const PageSubheader = (props: Props) => {
  return (
    <Typography.Title
      level={2}
      className='page-subheader page-subheader-main'
      style={props.style}
    >
      {props.children}
    </Typography.Title>
  )
}

export default PageSubheader
