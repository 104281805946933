import React from 'react'
import './programs.less'
import '../../layouts/AppLayout/appLayout.less'
import mainImage from '../../images/video.jpg'
import { Col, Row, Typography } from 'antd'
import { Program } from '../../providers/ClientProvider/client/services/programs/types'
import { useFindQuery } from '../../query'

const { Title } = Typography

interface IProps {
  programs?: Program[]
  handleClick: (id: string) => void
}

const ProgramsView = (props: IProps) => {
  const { data: levelsRes } = useFindQuery('levels', {})
  return (
    <div className='programs'>
      <div className='programs__gradient'/>
      <div style={{ height: '180px', width: '100%' }}/>
      <Title level={1} className='programs__title'>
        Zacznij pilates z PROGRAMEM!<br/>
        Daj się poprowadzić!
      </Title>
      <Row gutter={[{ xs: 16, md: 32 }, 16]}>
        {props.programs?.map(program =>
          <Col key={program._id} xs={12} md={8}>
            <div onClick={() => props.handleClick(program._id)} className='programs__container'>
              <img src={program.image || mainImage} alt='' width='100%' height='auto' className='programs__image' />
              <Title level={4} className='programs__tile__title'>
                {program.name}
              </Title>
              <Title level={4} className='programs__tile_subtitle' style={{ marginTop: '0' }}>
                Program {levelsRes?.data.find((level) => program.levelId === level._id)?.name}
              </Title>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default ProgramsView
