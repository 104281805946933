import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import './legalAgreement.less'
import { Button } from 'antd'

const LegalAgreement = () => {
  const [counter, setCounter] = useState(0)
  const agreement = useMemo(() => localStorage.getItem('agreement') === 'true', [counter])
  if (agreement) return null
  return (
    <div className='legal-agreement'>
      <div className='legal-agreement__card'>
        Wchodząc na stronę akceptujesz pliki cookies. Więcej informacji znajdziesz w zakładce
        <div>
          <Link to='/privacy'>Polityka prywatnośći</Link>
          <Button type={'primary'} onClick={() => {
            localStorage.setItem('agreement', 'true')
            setCounter(counter + 1)
          }}>Akceptuję</Button>
        </div>
      </div>
    </div>
  )
}

export default LegalAgreement
