import { useCreateMutation, useGetQuery } from '../../query'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { loadStripe } from '@stripe/stripe-js'
import config from '../../config'
import { useHistory } from 'react-router-dom'

const stripePromise = loadStripe(config.stripePublicKey)

const useSuccessVerifyEmail = () => {
  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const history = useHistory()
  const [saving, setSaving] = useState(false)

  const {
    data: client
  } = useGetQuery('clients', clientId)

  const [
    createStripeCheckoutSessions,
    {
      status: createStatus
    }
  ] = useCreateMutation('stripeCheckoutSessions')

  const onButtonPress = useCallback(async () => {
    if (!client) {
      history.replace('/login')
      return
    }

    const stripe = await stripePromise
    if (!stripe) throw new Error('Stripe failed to initialize')

    try {
      setSaving(true)

      const session = await createStripeCheckoutSessions({
        clientId: client._id,
        planId: client.subscriptionPlanId
      })

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id
      })
    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }, [client])

  return {
    onButtonPress,
    saving
  }
}

export default useSuccessVerifyEmail
