export interface VideosState {
  loading: boolean
  videoIds: string[]
  total: number
  currentPage: number
  targetIds: string[]
  levelIds: string[]
  equipmentIds: string[]
}

export const SET_VIDEOS = 'SET_VIDEOS'

export interface SetVideosAction {
  type: typeof SET_VIDEOS,
  payload: Partial<VideosState>
}

export type VideosActionTypes = SetVideosAction
