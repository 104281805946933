import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { appRoutes } from '../containers/Router/routes'
import { Favorite } from '../providers/ClientProvider/client/services/favorites/types.d'
import { Planned } from '../providers/ClientProvider/client/services/planned/types'
import { Video } from '../providers/ClientProvider/client/services/videos/types'
import { Watched } from '../providers/ClientProvider/client/services/watchedVideos/types'
import { useCreateMutation, useDeleteMutation, useGetQuery } from '../query'
import { RootState } from '../redux/reducers'

const useVideoCard = (video?: Video, favorite?: Favorite, watched?: Watched, planned?: Planned) => {
  const history = useHistory()

  const clientId = useSelector((state: RootState) => state.appState.clientId)
  const activeAccount = useSelector((state: RootState) => state.appState.activeAccount)

  const [createFavorite] = useCreateMutation('favoriteVideos')
  const [deleteFavorite] = useDeleteMutation('favoriteVideos')
  const [createPlanned] = useCreateMutation('plannedVideos')
  const [deletePlanned] = useDeleteMutation('plannedVideos')
  const [createWatched] = useCreateMutation('watchedVideos')

  const {
    data: level,
  } = useGetQuery('levels', video?.levelId)
  const {
    data: equipment,
  } = useGetQuery('equipments', video?.equipmentId)

  const isFavorite = !!favorite
  const isPlanned = !!planned

  const toggleFavorite = useCallback(async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!clientId) throw new Error('clientId not specified')
    if (isFavorite) {
      await deleteFavorite(favorite._id)
    } else {
      await createFavorite({
        clientId: clientId,
        videoId: video?._id
      })
    }
  }, [clientId, isFavorite, deleteFavorite, createFavorite])

  const togglePlanned = useCallback(async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (!clientId) throw new Error('clientId not specified')
    if (isPlanned) {
      await deletePlanned(planned._id)
    } else {
      await createPlanned({
        clientId: clientId,
        videoId: video?._id
      })
    }
  }, [clientId, isPlanned, deletePlanned, createPlanned])

  const handleCardClick = useCallback(() => {
    history.push(generatePath(appRoutes.videoItem.path, { id: video?._id }))
  }, [history])

  const thumbnail = useMemo(() => {
    return video?.thumbnail?.url.replace('?image_crop_resized=200x120', '')
  }, [video])

  const setAsWatched = useCallback(async () => {
    if (!watched && clientId) {
      await createWatched({
        clientId: clientId,
        videoId: video?._id
      })
    }
  }, [watched, clientId, createWatched])

  return {
    video,
    thumbnail,
    level,
    equipment,
    favorite,
    toggleFavorite,
    planned,
    togglePlanned,
    watched,
    handleCardClick,
    clientId,
    isFavorite,
    isPlanned,
    activeAccount,
    setAsWatched
  }
}

export default useVideoCard
