import React from 'react'
import './mainImage.less'
// @ts-ignore
import Img from 'react-cloudinary-lazy-image'

interface MainImageProps {
  image: string
  className?: string
  style?: any
  small?: boolean
}

const MainImage = (props: MainImageProps) => (
  <div
    className={`app-layout__main-image main-image ${props.className}`}
    style={props.small ? {
      height: 330
    } : undefined}
  >
    <Img
      cloudName={'prossto-agata-jedrzejczuk'}
      imageName={`frontend_images/${props.image}`}
      fluid={{
        maxWidth: 1760
      }}
      imgStyle={{
        objectFit: 'cover',
        objectPosition: '50% 100%',
        ...props.style
      }}
    />
  </div>
)

export default MainImage
