import React from 'react'
import SelectSubscriptionView from './SelectSubscriptionView'
import useSelectSubscription from './useSelectSubscription'

const SelectSubscription = () => {
  const state = useSelectSubscription()

  return (
    <SelectSubscriptionView
      {...state}
    />
  )
}

export default SelectSubscription
