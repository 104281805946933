import React from 'react'
import { Form, Input, Button, Checkbox, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import './login.less'
import { Link } from 'react-router-dom'
import MainImage from '../../components/MainImage/MainImage'
import { appRoutes } from '../../containers/Router/routes'

interface LoginViewProps {
  saving: boolean
  onFinish: (values: any) => void
}

const LoginView = (props: LoginViewProps) => {
  return (
    <div className='login'>
      <MainImage image='account_yzdrit.jpg' style={{ objectPosition: '50% 55%' }} small />
      <Form
        name='login'
        className='login-form'
        initialValues={{ remember: true }}
        onFinish={props.onFinish}
        size='large'
      >
        <Typography.Title level={2} style={{ textAlign: 'center' }}>Logowanie</Typography.Title>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'Wpisz adres email.' }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder='Email'
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Wpisz hasło' }]}
        >
          <Input
            prefix={<LockOutlined />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name='remember' valuePropName='checked' noStyle>
            <Checkbox>Zapamiętaj mnie</Checkbox>
          </Form.Item>

          <Link
            className='login-form-forgot'
            to='/reset-password'
          >
            Przypomnij hasło
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            disabled={props.saving}
          >
            {props.saving ? 'Ładowanie...' : 'Zaloguj'}
          </Button>
          Lub <Link to={appRoutes.selectSubscription.path}>zarejestruj się!</Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default LoginView
